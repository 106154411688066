'use client'

import React, { useState, useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { 
  ChevronDown, 
  ChevronRight, 
  User, 
  Briefcase, 
  MapPin, 
  Calendar, 
  Users,
  Brain
} from 'lucide-react'

import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import api from '../api'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "./ui/dialog"
import { Button } from "./ui/button"
import { Checkbox } from "./ui/checkbox"

// Update types to match the new schema
type KPI = {
  id: number;
  description: string;
  needs_review: boolean;
}

type KRA = {
  id: number;
  description: string;
  needs_review: boolean;
  responsibilities: Array<string | { description: string }>;
}

type Decision = {
  id: number;
  description: string;
  status: string;
  decision_priority: string;
  deadline: string;
  decision_level: string;
  inform: Array<{ employee_id: string; name: string }>;
  advise: Array<{ employee_id: string; name: string }>;
  decide: Array<{ employee_id: string; name: string }>;
  execute: Array<{ employee_id: string; name: string }>;
}

type Role = {
  id: number;
  title: string;
  department: string;
  location: string;
  grade: string;
  reporting_to: {
    id: number;
    title: string;
    current_employee: {
      id: number;
      name: string;
      employee_id: string;
    } | null;
  } | null;
  kras: KRA[];
  kpis: KPI[];
}

type Employee = {
  id: number;
  employee_id: string;
  name: string;
  email: string;
  date_of_joining: string | null;
  is_active: boolean;
  competencies: Array<string | { description: string }>;
}

type EmployeeData = {
  employee: Employee;
  role: Role;
  decisions: Decision[];
  has_acknowledged_kras: boolean;
}

const statusColors: { [key: string]: string } = {
  'To-Do': 'text-yellow-600 bg-yellow-50',
  'Doing': 'text-blue-600 bg-blue-50',
  'Done': 'text-green-600 bg-green-50',
  'Stuck': 'text-red-600 bg-red-50'
}

const priorityColors: { [key: string]: string } = {
  'Critical': 'text-red-600 bg-red-50',
  'High': 'text-orange-600 bg-orange-50',
  'Medium': 'text-yellow-600 bg-yellow-50',
  'Low': 'text-green-600 bg-green-50'
}

const decisionLevelColors: { [key: string]: string } = {
  'Strategic': 'text-purple-600 bg-purple-50',
  'Tactical': 'text-indigo-600 bg-indigo-50',
  'Routine': 'text-blue-600 bg-blue-50'
}

const categoryColors: { [key: string]: string } = {
  'I': 'border-l-blue-400',
  'A': 'border-l-green-400',
  'D': 'border-l-purple-400',
  'E': 'border-l-orange-400'
}

const EmployeeDashboard: React.FC = () => {
  const { user } = useAuth()
  const [employeeData, setEmployeeData] = useState<EmployeeData | null>(null)
  const [expandedKRAs, setExpandedKRAs] = useState<boolean>(false)
  const [expandedKPIs, setExpandedKPIs] = useState<boolean>(false)
  const [expandedCompetencies, setExpandedCompetencies] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [showAcknowledgmentModal, setShowAcknowledgmentModal] = useState(false)
  const [isAcknowledged, setIsAcknowledged] = useState(false)

  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (!user?.employeeId) {
        setError('Employee ID not found. Please log in again.')
        setLoading(false)
        return
      }

      try {
        const response = await api.get(`/api/employee-dashboard/${user.employeeId}/`)
        setEmployeeData(response.data)
      } catch (err) {
        console.error('Error fetching employee data:', err)
        setError('Failed to load employee data. Please try again later.')
      } finally {
        setLoading(false)
      }
    }

    fetchEmployeeData()
  }, [user])

  useEffect(() => {
    if (user && user.role === 'employee' && !user.hasAcknowledgedKras) {
      setShowAcknowledgmentModal(true);
    }
  }, [user]);

  const handleAcknowledgment = async () => {
    try {
      const response = await api.post('/api/accounts/acknowledge-kras/', {
        userId: user?.userId
      });
      
      if (response.data.status === 'success') {
        setShowAcknowledgmentModal(false);
        const updatedUser = { ...user!, hasAcknowledgedKras: true };
        localStorage.setItem('user', JSON.stringify(updatedUser));
      }
    } catch (error) {
      console.error('Error acknowledging KRAs:', error);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-600">{error}</div>
  }

  if (!employeeData) {
    return <div className="flex justify-center items-center h-screen">No employee data available.</div>
  }

  const { employee, role, decisions } = employeeData

  const categorizedDecisions = {
    I: decisions.filter(d => d.inform?.some(e => e.employee_id === employee.employee_id) ?? false),
    A: decisions.filter(d => d.advise?.some(e => e.employee_id === employee.employee_id) ?? false),
    D: decisions.filter(d => d.decide?.some(e => e.employee_id === employee.employee_id) ?? false),
    E: decisions.filter(d => d.execute?.some(e => e.employee_id === employee.employee_id) ?? false),
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`
  }

  return (
    <div className="flex flex-col h-full w-full bg-cream text-gray-900">
      {/* Header */}
      <div className="sticky top-0 bg-gradient-to-r from-blue-50 to-indigo-50 z-20 px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-bold text-gray-800">{employee.name}</h1>
        <p className="text-gray-600">{role.title} - {role.department}</p>
      </div>

      {/* Main Content */}
      <div className="flex-grow overflow-auto px-6 py-4">
        {/* Employee Details Card */}
        <Card className="mb-6 shadow-md">
          <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
            <CardTitle>Employee Details</CardTitle>
          </CardHeader>
          <CardContent className="grid grid-cols-2 gap-4 p-6">
            <div className="flex items-center">
              <User size={16} className="mr-2 text-muted-foreground" />
              <span>{employee.employee_id}</span>
            </div>
            <div className="flex items-center">
              <Briefcase size={16} className="mr-2 text-muted-foreground" />
              <span>{role.department}</span>
            </div>
            <div className="flex items-center">
              <MapPin size={16} className="mr-2 text-muted-foreground" />
              <span>{role.location}</span>
            </div>
            <div className="flex items-center">
              <Users size={16} className="mr-2 text-muted-foreground" />
              <span>{role.grade}</span>
            </div>
            <div className="flex items-center col-span-2">
              <Calendar size={16} className="mr-2 text-muted-foreground" />
              <span>Joined: {employee.date_of_joining && new Date(employee.date_of_joining).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center col-span-2">
              <Users size={16} className="mr-2 text-muted-foreground" />
              <span>Manager: {role.reporting_to?.current_employee?.name || 'Not Assigned'}</span>
            </div>
          </CardContent>
        </Card>

        <div className="grid grid-cols-2 gap-6">
          {/* KRAs Section */}
          <Card className="shadow-md">
            <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
              <div className="flex items-center justify-between">
                <CardTitle>Key Result Areas (KRAs)</CardTitle>
                <button onClick={() => setExpandedKRAs(!expandedKRAs)}>
                  {expandedKRAs ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                </button>
              </div>
            </CardHeader>
            <CardContent className={`transition-all duration-200 ${expandedKRAs ? 'block' : 'hidden'}`}>
              {role.kras?.length > 0 ? (
                role.kras.map((kra) => (
                  <div key={kra.id} className="mb-4 last:mb-0 p-3 bg-muted rounded-lg">
                    <p className="font-medium">{kra.description}</p>
                    {Array.isArray(kra.responsibilities) && kra.responsibilities.length > 0 && (
                      <div className="mt-2 pl-4 border-l-2 border-gray-200">
                        <p className="text-sm font-medium text-muted-foreground mb-1">Responsibilities:</p>
                        <ul className="list-disc list-inside space-y-1">
                          {kra.responsibilities.map((resp, index) => (
                            <li key={index} className="text-sm text-gray-600">
                              {typeof resp === 'string' ? resp : resp.description}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-muted-foreground">No KRAs available</p>
              )}
            </CardContent>
          </Card>

          {/* KPIs Section */}
          <Card className="shadow-md">
            <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
              <div className="flex items-center justify-between">
                <CardTitle>Key Performance Indicators (KPIs)</CardTitle>
                <button onClick={() => setExpandedKPIs(!expandedKPIs)}>
                  {expandedKPIs ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                </button>
              </div>
            </CardHeader>
            <CardContent className={`transition-all duration-200 ${expandedKPIs ? 'block' : 'hidden'}`}>
              {role.kpis?.length > 0 ? (
                role.kpis.map((kpi) => (
                  <div key={kpi.id} className="mb-4 last:mb-0 p-3 bg-muted rounded-lg">
                    <p className="font-medium">{kpi.description}</p>
                  </div>
                ))
              ) : (
                <p className="text-muted-foreground">No KPIs available</p>
              )}
            </CardContent>
          </Card>
        </div>

        {/* Decisions Section */}
        <Card className="mt-6 shadow-md">
          <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
            <CardTitle>Decisions</CardTitle>
          </CardHeader>
          <CardContent>
            {Object.entries(categorizedDecisions).map(([category, decisions]) => (
              <div key={category} className="mb-6 last:mb-0">
                <h3 className="text-lg font-semibold mb-2">{getCategoryFullName(category)}</h3>
                <div className={`pl-4 border-l-4 ${categoryColors[category]}`}>
                  {decisions.length > 0 ? (
                    decisions.map(decision => (
                      <div key={decision.id} className="mb-4 last:mb-0 p-3 bg-muted rounded-lg">
                        <p className="font-medium">{decision.description}</p>
                        <div className="flex flex-wrap gap-2 mt-2">
                          <span className={`text-xs px-2 py-1 rounded-full ${statusColors[decision.status]}`}>
                            {decision.status}
                          </span>
                          <span className={`text-xs px-2 py-1 rounded-full ${priorityColors[decision.decision_priority]}`}>
                            {decision.decision_priority}
                          </span>
                          <span className={`text-xs px-2 py-1 rounded-full ${decisionLevelColors[decision.decision_level]}`}>
                            {decision.decision_level}
                          </span>
                          <span className="text-xs px-2 py-1 rounded-full bg-gray-100 text-gray-600">
                            Deadline: {formatDate(decision.deadline)}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-muted-foreground">No {getCategoryFullName(category)} decisions</p>
                  )}
                </div>
              </div>
            ))}
          </CardContent>
        </Card>

        {/* Competencies Section */}
        <Card className="mt-6 shadow-md">
          <CardHeader className="bg-gradient-to-r from-blue-50 to-indigo-50">
            <div className="flex items-center justify-between">
              <CardTitle className="flex items-center">
                <Brain size={20} className="mr-2" />
                Competencies
              </CardTitle>
              <button onClick={() => setExpandedCompetencies(!expandedCompetencies)}>
                {expandedCompetencies ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              </button>
            </div>
          </CardHeader>
          <CardContent className={`transition-all duration-200 ${expandedCompetencies ? 'block' : 'hidden'}`}>
            {employee.competencies?.length > 0 ? (
              employee.competencies.map((competency, index) => (
                <div key={index} className="mb-4 last:mb-0 p-3 bg-muted rounded-lg">
                  <p className="font-medium">{typeof competency === 'string' ? competency : competency.description}</p>
                </div>
              ))
            ) : (
              <p className="text-muted-foreground">No competencies available</p>
            )}
          </CardContent>
        </Card>
      </div>

      {showAcknowledgmentModal && (
        <Dialog open={showAcknowledgmentModal} onOpenChange={setShowAcknowledgmentModal}>
          <DialogContent className="max-w-2xl">
            <DialogHeader>
              <DialogTitle>Welcome to Your Role</DialogTitle>
              <DialogDescription>
                Please review your Key Result Areas (KRAs) and Key Performance Indicators (KPIs) carefully.
                You need to acknowledge these before proceeding.
              </DialogDescription>
            </DialogHeader>
            
            <div className="max-h-[60vh] overflow-y-auto">
              <div className="space-y-4">
                <h3 className="font-semibold">Key Result Areas (KRAs)</h3>
                {role.kras?.map((kra) => (
                  <div key={kra.id} className="p-3 bg-muted rounded-lg">
                    <p>{kra.description}</p>
                    {kra.responsibilities && (
                      <ul className="mt-2 ml-4 list-disc">
                        {kra.responsibilities.map((resp, idx) => (
                          <li key={idx} className="text-sm">
                            {typeof resp === 'string' ? resp : resp.description}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}

                <h3 className="font-semibold mt-6">Key Performance Indicators (KPIs)</h3>
                {role.kpis?.map((kpi) => (
                  <div key={kpi.id} className="p-3 bg-muted rounded-lg">
                    <p>{kpi.description}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex items-center space-x-2 mt-4">
              <Checkbox
                id="acknowledge"
                checked={isAcknowledged}
                onCheckedChange={(checked) => setIsAcknowledged(checked as boolean)}
              />
              <label
                htmlFor="acknowledge"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I acknowledge that I have read and understood my KRAs and KPIs
              </label>
            </div>

            <DialogFooter>
              <Button
                onClick={handleAcknowledgment}
                disabled={!isAcknowledged}
              >
                Acknowledge and Continue
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

function getCategoryFullName(category: string): string {
  switch (category) {
    case 'I': return 'Inform'
    case 'A': return 'Advise'
    case 'D': return 'Decide'
    case 'E': return 'Execute'
    default: return category
  }
}

export default EmployeeDashboard
