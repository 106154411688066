"use client"

import { useState, useEffect } from "react"
import api from '../../api'
import { motion, AnimatePresence } from "framer-motion"
import { Rocket, Lightbulb, Shield, Settings } from 'lucide-react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion"
import { ScrollArea } from "../ui/scroll-area"
import { Separator } from "../ui/separator"
import { useAuth } from '../../contexts/AuthContext'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../ui/alert-dialog"
import { Input } from "../ui/input"
import { Button } from "../ui/button"

const ThemeDialog = ({ 
  isOpen, 
  onClose, 
  currentTheme, 
  onUpdateTheme 
}: { 
  isOpen: boolean;
  onClose: () => void;
  currentTheme: string;
  onUpdateTheme: (theme: string) => void;
}) => {
  const [theme, setTheme] = useState(currentTheme);

  const handleSubmit = () => {
    onUpdateTheme(theme);
    onClose();
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Update Weekly Theme</AlertDialogTitle>
          <AlertDialogDescription>
            Set the theme for this week's moonshot ideas.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="py-4">
          <Input
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
            placeholder="Enter weekly theme"
            className="w-full"
          />
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleSubmit} disabled={!theme.trim()}>
            Update Theme
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default function PromotorSummaryPage() {
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [activeSection, setActiveSection] = useState<string | null>(null)
  const [isThemeDialogOpen, setIsThemeDialogOpen] = useState(false);
  const [currentTheme, setCurrentTheme] = useState<string>("No theme set");
  const { user } = useAuth()

  useEffect(() => {
    const companyName = user?.company || 'Kaynes';
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/moonshots/${companyName}/summary/`);
        setData(response.data);
        setCurrentTheme(response.data.weeklyTheme);
        setLoading(false);
      } catch (error: any) {
        console.error('Error fetching summary:', error);
        if (error.response) {
          setError(`Error ${error.response.status}: ${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
          setError('No response received from the server. Please try again later.');
        } else {
          setError('An unexpected error occurred. Please try again later.');
        }
        setLoading(false);
      }
    };
    
    fetchData();
  }, [user?.company]); // Specify the exact dependency

  const handleCardClick = (section: string) => {
    setActiveSection(activeSection === section ? null : section)
  }

  const renderSummaryCards = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <Card className="cursor-pointer" onClick={() => handleCardClick('moonshots')}>
        <CardHeader className="pb-2">
          <CardTitle className="text-sm font-medium flex items-center">
            <Rocket className="mr-2 h-4 w-4" />
            Total Moonshots
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{data.moonshot_ideas}</div>
        </CardContent>
      </Card>
      <Card className="cursor-pointer" onClick={() => handleCardClick('innovations')}>
        <CardHeader className="pb-2">
          <CardTitle className="text-sm font-medium flex items-center">
            <Lightbulb className="mr-2 h-4 w-4" />
            Total Innovations
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{data.innovations}</div>
        </CardContent>
      </Card>
      <Card className="cursor-pointer" onClick={() => handleCardClick('whistleblower')}>
        <CardHeader className="pb-2">
          <CardTitle className="text-sm font-medium flex items-center">
            <Shield className="mr-2 h-4 w-4" />
            Whistleblower Reports
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{data.whistleblowerReports.length}</div>
        </CardContent>
      </Card>
    </div>
  )

  const renderMoonshots = () => (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle className="flex items-center">
            <Rocket className="mr-2" />
            Moonshot Ideas
          </CardTitle>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setIsThemeDialogOpen(true)}
            title="Update Weekly Theme"
          >
            <Settings className="h-4 w-4" />
          </Button>
        </div>
        <CardDescription>Weekly Theme: {currentTheme}</CardDescription>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px] pr-4">
          <Accordion type="single" collapsible className="w-full">
            {data.moonshot_ideas_details.map((moonshot: any, index: number) => (
              <AccordionItem value={`item-${index}`} key={moonshot.id}>
                <AccordionTrigger>
                  <div className="flex flex-col items-start">
                    <span>{moonshot.idea}</span>
                    <span className="text-sm text-muted-foreground">{moonshot.employee}</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="mt-2">
                    <h4 className="font-semibold">Plan:</h4>
                    <p className="text-sm">{moonshot.plan || 'No plan provided'}</p>
                  </div>
                  <div className="mt-2">
                    <h4 className="font-semibold">Resources:</h4>
                    <p className="text-sm">{moonshot.resources || 'No resources specified'}</p>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </ScrollArea>
      </CardContent>
    </Card>
  )

  const renderInnovations = () => (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Lightbulb className="mr-2" />
          Innovation Ideas
        </CardTitle>
        <CardDescription>All submitted innovation ideas</CardDescription>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px] pr-4">
          <ul className="space-y-4">
            {data.innovations_details.map((innovation: any) => (
              <li key={innovation.id}>
                <p className="font-medium">{innovation.idea}</p>
                <div className="flex justify-between items-center mt-1">
                  <span className="text-sm bg-yellow-100 px-2 py-1 rounded">{innovation.type}</span>
                  <span className="text-sm text-muted-foreground">{innovation.employee}</span>
                </div>
                <Separator className="my-2" />
              </li>
            ))}
          </ul>
        </ScrollArea>
      </CardContent>
    </Card>
  )

  const renderWhistleblower = () => (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Shield className="mr-2" />
          Whistleblower Reports
        </CardTitle>
        <CardDescription>Anonymous whistleblower reports</CardDescription>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px] pr-4">
          <ul className="space-y-4">
            {data.whistleblowerReports.map((report: any) => (
              <li key={report.id}>
                <p className="font-medium">{report.report}</p>
                <Separator className="my-2" />
              </li>
            ))}
          </ul>
        </ScrollArea>
      </CardContent>
    </Card>
  )

  const handleUpdateTheme = async (newTheme: string) => {
    try {
      const companyName = user?.company || 'Kaynes';
      const response = await api.post(`/api/moonshots/${companyName}/theme/`, {
        theme: newTheme
      });
      
      if (response.data.theme) {
        setCurrentTheme(response.data.theme);
        // Refresh the data to get updated theme
        fetchData();
      }
    } catch (error) {
      console.error('Error updating theme:', error);
    }
  };

  if (loading) return <div className="text-center py-4">Loading...</div>
  if (error) return (
    <div className="text-center py-4 text-red-600">
      <p>Error loading data:</p>
      <p>{error}</p>
      <button 
        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => window.location.reload()}
      >
        Retry
      </button>
    </div>
  )

  return (
    <div className="p-4 bg-gradient-to-r from-[#FFF5D6] to-[#F2E2A8]">
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Moonshots Dashboard</h2>
        <p className="text-muted-foreground">
          View moonshot ideas, feedback, and anonymous reports submitted by employees
        </p>
      </div>
      {renderSummaryCards()}
      <AnimatePresence>
        {activeSection && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            {activeSection === 'moonshots' && renderMoonshots()}
            {activeSection === 'innovations' && renderInnovations()}
            {activeSection === 'whistleblower' && renderWhistleblower()}
          </motion.div>
        )}
      </AnimatePresence>
      <ThemeDialog
        isOpen={isThemeDialogOpen}
        onClose={() => setIsThemeDialogOpen(false)}
        currentTheme={currentTheme}
        onUpdateTheme={handleUpdateTheme}
      />
    </div>
  )
}
