'use client'

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronDown, ChevronUp, Filter, Search, User, BarChart2, Calendar, Check, Plus, Trash2, Edit2, Flag, GitBranch, AlertTriangle, ChevronRight, CheckCircle, List } from 'lucide-react';
import axios from 'axios';
import { Popover } from '@headlessui/react';
import Plot from 'react-plotly.js';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion"

import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { Card, CardContent} from "../ui/card"
import api from '../../api';
import { useAuth } from '../../contexts/AuthContext'; // Add this import
import { createPortal } from 'react-dom';
import { useFloating, offset, shift, flip } from '@floating-ui/react';
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogCancel, AlertDialogAction } from "../ui/alert-dialog"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select"
import { Label } from "../ui/label"

type Employee = {
  employee_id: string;
  name: string;
};

type Decision = {
  id: number;
  company: string;
  inform: Employee[];
  advise: Employee[];
  decide: Employee[];
  execute: Employee[];
  decision_id: string;
  deadline: string;
  decision_level: string;
  status: string;
  decision_priority: string;
  description: string;
  execute_best_practice: boolean;
  decide_best_practice: boolean;
  department: string;
  area_of_focus: string;
};

const priorityColors: { [key: string]: string } = {
  'Critical': 'text-red-600',
  'High': 'text-orange-600',
  'Medium': 'text-yellow-600',
  'Low': 'text-green-600'
};

const decisionLevels = ['Strategic', 'Tactical', 'Routine'];
const statusOptions = ['To-Do', 'Doing', 'Done', 'Stuck'];
const priorityOptions = ['Critical', 'High', 'Medium', 'Low'];

// Add these constants at the top of your file, after the imports
const statusSortOrder = ['Stuck', 'To-Do', 'Doing', 'Done'];
const prioritySortOrder = ['Critical', 'High', 'Medium', 'Low'];

// Update the SunburstData type definition to be more generic
type SunburstData = {
  data: any[];
  layout: any;
};

// Add this new type definition
type GroupedDecisions = {
  [department: string]: {
    [areaOfFocus: string]: Decision[];
  };
};

// Add this function to generate a pastel color based on a string
const generatePastelColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 80%)`;
};

const CriticalAlertIndicator = ({ onClick, criticalCount, totalCount }: { 
  onClick: () => void, 
  criticalCount: number, 
  totalCount: number 
}) => {
  const violationPercentage = (criticalCount / totalCount) * 100;
  
  let bgColor, textColor, icon;
  if (criticalCount === 0) {
    bgColor = 'bg-green-100';
    textColor = 'text-green-800';
    icon = <CheckCircle className="h-4 w-4" />;
  } else if (violationPercentage < 50) {
    bgColor = 'bg-yellow-100';
    textColor = 'text-yellow-800';
    icon = <AlertTriangle className="h-4 w-4" />;
  } else {
    bgColor = 'bg-red-100';
    textColor = 'text-red-800';
    icon = <AlertTriangle className="h-4 w-4" />;
  }

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={onClick}
      className={`flex items-center space-x-2 ${bgColor} ${textColor}`}
    >
      {icon}
      <span>{criticalCount}/{totalCount}</span>
    </Button>
  );
};

// Add this new component for expandable text
const ExpandableText = ({ text, maxLength = 100 }: { text: string; maxLength?: number }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const needsExpansion = text.length > maxLength;

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {isExpanded || !needsExpansion ? text : `${text.slice(0, maxLength)}...`}
      {needsExpansion && (
        <button
          onClick={toggleExpansion}
          className="ml-1 text-blue-500 hover:text-blue-700 text-sm font-medium focus:outline-none"
        >
          {isExpanded ? 'Read less' : 'Read more'}
        </button>
      )}
    </div>
  );
};

// Update the NewDecisionDialog props to remove the unnecessary handlers
const NewDecisionDialog = ({ 
  isOpen, 
  onClose,
  departments,
  onCreateDecision,
}: { 
  isOpen: boolean;
  onClose: () => void;
  departments: Department[];
  onCreateDecision: (department: string, areaOfFocus: string) => void;
}) => {
  const [selectedDept, setSelectedDept] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [newDeptName, setNewDeptName] = useState('');
  const [newAreaName, setNewAreaName] = useState('');

  const handleCreate = () => {
    let finalDepartment = selectedDept || newDeptName;
    let finalArea = selectedArea || newAreaName;

    if ((selectedDept || newDeptName) && (selectedArea || newAreaName)) {
      onCreateDecision(finalDepartment, finalArea);
      onClose();
      // Reset form
      setSelectedDept('');
      setSelectedArea('');
      setNewDeptName('');
      setNewAreaName('');
    }
  };

  if (!isOpen) return null;

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="sm:max-w-[425px]">
        <AlertDialogHeader>
          <AlertDialogTitle>Create New Decision</AlertDialogTitle>
          <AlertDialogDescription>
            Select an existing department and area or enter new ones.
          </AlertDialogDescription>
        </AlertDialogHeader>
        
        <div className="grid gap-4 py-4">
          {/* Department Selection/Creation */}
          <div className="space-y-2">
            <Label>Department</Label>
            <div className="space-y-2">
              <Select
                value={selectedDept}
                onValueChange={(value) => {
                  setSelectedDept(value);
                  setNewDeptName('');
                }}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select existing department" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  {departments.map((dept) => (
                    <SelectItem key={dept.name} value={dept.name}>
                      {dept.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              
              <div className="flex items-center gap-2">
                <span className="text-sm text-muted-foreground">or</span>
                <Input
                  value={newDeptName}
                  onChange={(e) => {
                    setNewDeptName(e.target.value);
                    setSelectedDept('');
                  }}
                  placeholder="Enter new department name"
                  className="flex-grow"
                />
              </div>
            </div>
          </div>

          {/* Area of Focus Selection/Creation */}
          <div className="space-y-2">
            <Label>Area of Focus</Label>
            <div className="space-y-2">
              <Select
                value={selectedArea}
                onValueChange={(value) => {
                  setSelectedArea(value);
                  setNewAreaName('');
                }}
                disabled={!selectedDept}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select existing area" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  {departments
                    .find(d => d.name === selectedDept)
                    ?.areas_of_focus.map((area) => (
                      <SelectItem key={area} value={area}>
                        {area}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              
              <div className="flex items-center gap-2">
                <span className="text-sm text-muted-foreground">or</span>
                <Input
                  value={newAreaName}
                  onChange={(e) => {
                    setNewAreaName(e.target.value);
                    setSelectedArea('');
                  }}
                  placeholder="Enter new area name"
                  className="flex-grow"
                />
              </div>
            </div>
          </div>
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={handleCreate}
            disabled={
              (!selectedDept && !newDeptName) || 
              (!selectedArea && !newAreaName)
            }
          >
            Create Decision
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

// Move these type definitions to the top level
type Department = {
  name: string;
  areas_of_focus: string[];
};

export default function DecisionTable() {
  // Move all state declarations inside the component
  const [decisions, setDecisions] = useState<Decision[]>([]);
  const [sortColumn, setSortColumn] = useState<keyof Decision>('id');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [editingDescription, setEditingDescription] = useState<number | null>(null);
  const [editedDescription, setEditedDescription] = useState('');
  const [employeeFilter, setEmployeeFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [deadlineFilter, setDeadlineFilter] = useState<string>('');
  const [priorityFilter, setPriorityFilter] = useState<string[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [allEmployees, setAllEmployees] = useState<Employee[]>([]);
  const [criticalFilter, setCriticalFilter] = useState(false);
  const [criticalDecisions, setCriticalDecisions] = useState(0);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [isSummaryView, setIsSummaryView] = useState(false);
  const [sunburstData, setSunburstData] = useState<SunburstData | null>(null);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [expandedDepartments, setExpandedDepartments] = useState<string[]>([]);
  const [aofColors, setAofColors] = useState<{ [key: string]: string }>({});

  // Add the new state declarations here
  const [departments, setDepartments] = useState<Department[]>([]);
  const [isNewDecisionDialogOpen, setIsNewDecisionDialogOpen] = useState(false);

  const { user } = useAuth();

  const fetchDecisions = useCallback(async () => {
    try {
      const companyName = user?.company || 'Kaynes';
      const response = await api.get(`/api/decisions/${companyName}/`);
      setDecisions(response.data);
      
      const uniqueAoFs = Array.from(new Set(response.data.map((d: Decision) => d.area_of_focus)));
      const newAofColors = uniqueAoFs.reduce<{ [key: string]: string }>((acc, aof) => {
        if (aof) {
          acc[aof] = generatePastelColor(aof);
        }
        return acc;
      }, {});
      setAofColors(newAofColors);
    } catch (error) {
      console.error('Error fetching decisions:', error);
    }
  }, [user?.company]); // Add dependencies

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchEmployees = async () => {
    try {
      const companyName = user?.company || 'Kaynes';
      const response = await api.get(`/api/employees/${companyName}/`);
      setAllEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const fetchSunburstData = useCallback(() => {
    const companyName = user?.company || 'Kaynes';
    api.get(`/api/decision-sunburst/${companyName}/`)
      .then(response => {
        console.log('Raw response data:', response.data);
        setSunburstData(response.data);
      })
      .catch(error => {
        console.error('Error fetching sunburst data:', error);
      });
  }, [user?.company]); // Add dependencies

  const handleSort = (column: keyof Decision) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedDecisions = [...decisions].sort((a, b) => {
    if (sortColumn === 'status') {
      return (statusSortOrder.indexOf(a.status) - statusSortOrder.indexOf(b.status)) * (sortDirection === 'asc' ? 1 : -1);
    } else if (sortColumn === 'decision_priority') {
      return (prioritySortOrder.indexOf(a.decision_priority) - prioritySortOrder.indexOf(b.decision_priority)) * (sortDirection === 'asc' ? 1 : -1);
    } else {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    }
  }).filter(decision => 
    decision.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    decision.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
    decision.decision_priority.toLowerCase().includes(searchTerm.toLowerCase()) ||
    decision.decision_level.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredDecisions = sortedDecisions.filter(decision => {
    const employeeMatch = employeeFilter.length === 0 || employeeFilter.some(empId => 
      decision.inform.concat(decision.advise, decision.decide, decision.execute)
        .some(decisionEmp => decisionEmp.employee_id === empId)
    );
    const statusMatch = statusFilter.length === 0 || statusFilter.includes(decision.status);
    const priorityMatch = priorityFilter.length === 0 || priorityFilter.includes(decision.decision_priority);
    const typeMatch = typeFilter.length === 0 || typeFilter.includes(decision.decision_level);
    
    let deadlineMatch = true;
    if (deadlineFilter) {
      const today = new Date();
      const deadline = new Date(decision.deadline);
      const diffDays = Math.ceil((deadline.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
      
      if (deadlineFilter === 'overdue') {
        deadlineMatch = diffDays < 0;
      } else if (deadlineFilter === 'within3days') {
        deadlineMatch = diffDays >= 0 && diffDays <= 3;
      } else if (deadlineFilter === 'within7days') {
        deadlineMatch = diffDays >= 0 && diffDays <= 7;
      }
    }

    return employeeMatch && statusMatch && deadlineMatch && priorityMatch && typeMatch &&
      (!criticalFilter || (decision.execute_best_practice || decision.decide_best_practice));
  });

  useEffect(() => {
    const critical = decisions.filter(d => d.execute_best_practice || d.decide_best_practice).length;
    setCriticalDecisions(critical);
  }, [decisions]);

  const toggleCriticalFilter = () => {
    setCriticalFilter(!criticalFilter);
  };

  const updateDecision = async (id: number, field: keyof Decision, value: any) => {
    try {
      await api.patch(`/api/decisions/${id}/`, { [field]: value });
      
      // Update the local state to reflect the change immediately
      setDecisions(prevDecisions => 
        prevDecisions.map(decision => 
          decision.id === id ? { ...decision, [field]: value } : decision
        )
      );
    } catch (error) {
      console.error('Error updating decision:', error);
    }
  };

  const deleteDecision = async (id: number) => {
    try {
      // Comment out the axios call
      await api.delete(`/api/decisions/${id}/`);
      
      // Remove the deleted decision from the local state
      setDecisions(prevDecisions => prevDecisions.filter(decision => decision.id !== id));
    } catch (error) {
      console.error('Error deleting decision:', error);
    }
  };

  const startEditing = (id: number, description: string) => {
    setEditingDescription(id);
    setEditedDescription(description);
  };

  const saveDescription = async (id: number) => {
    try {
      await updateDecision(id, 'description', editedDescription);
      setEditingDescription(null);
    } catch (error) {
      console.error('Error updating description:', error);
    }
  };

  const EmployeeCell = ({ employees, onUpdate, isCritical, criticalType, decisionId, isExpanded, allEmployees }: { 
    employees: Employee[], 
    onUpdate: (employees: Employee[]) => void,
    isCritical: boolean,
    criticalType?: 'decide' | 'execute',
    decisionId: number,
    isExpanded: boolean,
    allEmployees: Employee[]
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles } = useFloating({
      placement: 'bottom-start',
      middleware: [offset(10), shift(), flip()],
    });

    const handleOpen = (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    };

    const displayText = isExpanded
      ? employees.map(emp => emp.name).join(', ') || '-'
      : employees.length > 0
        ? `${employees[0].name}${employees.length > 1 ? ` +${employees.length - 1}` : ''}`
        : '-';

    const handleUpdate = (newEmployees: Employee[]) => {
      onUpdate(newEmployees);
    };

    return (
      <div className="relative flex items-center space-x-1">
        <button
          ref={refs.setReference}
          onClick={handleOpen}
          onMouseOver={(e) => e.stopPropagation()}
          className={`w-full p-2 text-sm text-left flex items-center justify-between bg-transparent ${isCritical ? 'border border-red-300' : ''}`}
        >
          {isCritical && criticalType && (
            <div className="group relative mr-1 flex-shrink-0">
              <AlertTriangle className="h-4 w-4 text-red-500" />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
                {criticalType === 'decide' 
                  ? "Ensure there is exactly one decision maker assigned."
                  : "Ensure there is at least one executor assigned."}
              </span>
            </div>
          )}
          <span className={`flex-grow font-semibold text-gray-900 ${isExpanded ? '' : 'truncate'} ${displayText === '-' ? 'text-gray-400' : ''}`}>
            {displayText}
          </span>
          <ChevronDown className="h-4 w-4 text-gray-400 flex-shrink-0" />
        </button>
        {isOpen && createPortal(
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="z-50 w-80 bg-white border rounded-md shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <EmployeeSelector
              selectedEmployees={employees}
              onUpdate={handleUpdate}
              onClose={() => setIsOpen(false)}
              allEmployees={allEmployees}
            />
          </div>,
          document.body
        )}
      </div>
    );
  };

  const EmployeeSelector = ({ selectedEmployees, onUpdate, onClose, allEmployees }: { 
    selectedEmployees: Employee[], 
    onUpdate: (employees: Employee[]) => void, 
    onClose: () => void,
    allEmployees: Employee[]
  }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [localSelectedEmployees, setLocalSelectedEmployees] = useState<Employee[]>(selectedEmployees);

    const filteredEmployees = allEmployees.filter(
      emp => emp.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const toggleEmployee = (employee: Employee) => {
      setLocalSelectedEmployees(prev => {
        const isSelected = prev.some(e => e.employee_id === employee.employee_id);
        return isSelected
          ? prev.filter(e => e.employee_id !== employee.employee_id)
          : [...prev, employee];
      });
    };

    const handleDone = () => {
      onUpdate(localSelectedEmployees);
      onClose();
    };

    return (
      <div className="absolute z-50 mt-1 w-80 bg-white border rounded-md shadow-lg">
        <div className="p-2">
          <input
            type="text"
            placeholder="Search employees..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border rounded text-sm"
            autoComplete="off"
          />
        </div>
        <div className="max-h-60 overflow-y-auto">
          {filteredEmployees.map(employee => (
            <div
              key={employee.employee_id}
              className="p-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
              onClick={() => toggleEmployee(employee)}
            >
              <span>{employee.name}</span>
              {localSelectedEmployees.some(e => e.employee_id === employee.employee_id) && (
                <Check className="h-4 w-4 text-green-500" />
              )}
            </div>
          ))}
        </div>
        <div className="p-2 border-t flex justify-end">
          <button
            onClick={handleDone}
            className="px-4 py-2 bg-blue-500 text-white rounded text-sm"
          >
            Done
          </button>
        </div>
      </div>
    );
  };

  const AutoWidthSelect = ({ options, value, onChange, className }: { options: string[], value: string, onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void, className?: string }) => {
    const selectRef = useRef<HTMLSelectElement>(null);
    const [width, setWidth] = useState(100);

    useEffect(() => {
      if (selectRef.current) {
        const tempSpan = document.createElement('span');
        tempSpan.style.visibility = 'hidden';
        tempSpan.style.position = 'absolute';
        tempSpan.style.whiteSpace = 'nowrap';
        document.body.appendChild(tempSpan);

        let maxWidth = 0;
        options.forEach(option => {
          tempSpan.textContent = option;
          const optionWidth = tempSpan.offsetWidth;
          if (optionWidth > maxWidth) {
            maxWidth = optionWidth;
          }
        });

        document.body.removeChild(tempSpan);
        setWidth(maxWidth + 20); // Reduced padding
      }
    }, [options]);

    return (
      <div className="relative inline-block">
        <select
          ref={selectRef}
          value={value}
          onChange={onChange}
          className={`appearance-none bg-transparent pr-6 text-xs font-medium ${className}`}
          style={{ width: `${width}px` }}
        >
          {options.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <ChevronDown className="absolute right-0 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
      </div>
    );
  };

  const updateEmployees = async (id: number, field: 'inform' | 'advise' | 'decide' | 'execute', employees: Employee[]) => {
    try {
      const payload = { 
        [`${field}_ids`]: employees.map(e => e.employee_id)
      };
      console.log('Sending payload:', payload);
      const response = await api.patch(`/api/decisions/${id}/`, payload);
      
      console.log('Response:', response.data);

      // Update the local state with the new employees and recalculate best practice flags
      setDecisions(prevDecisions => {
        const updatedDecisions = prevDecisions.map(decision => {
          if (decision.id === id) {
            const updatedDecision = { ...decision, [field]: employees };
            // Recalculate best practice flags
            updatedDecision.execute_best_practice = updatedDecision.execute.length === 0;
            updatedDecision.decide_best_practice = updatedDecision.decide.length !== 1;
            return updatedDecision;
          }
          return decision;
        });

        // Update the critical decisions count based on the new state
        const newCriticalCount = updatedDecisions.filter(d => d.execute_best_practice || d.decide_best_practice).length;
        setCriticalDecisions(newCriticalCount);

        return updatedDecisions;
      });

    } catch (error) {
      console.error('Error updating employees:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Response data:', error.response.data);
      }
    }
  };

  const MultiSelectFilter = ({ options, selectedOptions, onChange }: {
    options: string[],
    selectedOptions: string[],
    onChange: (selected: string[]) => void,
  }) => {
    const toggleOption = (option: string) => {
      const newSelection = selectedOptions.includes(option)
        ? selectedOptions.filter(item => item !== option)
        : [...selectedOptions, option];
      onChange(newSelection);
    };

    return (
      <div className="p-4 bg-white rounded-lg shadow-lg">
        {options.map(option => (
          <div key={option} className="flex items-center mb-2">
            <input
              type="checkbox"
              id={option}
              checked={selectedOptions.includes(option)}
              onChange={() => toggleOption(option)}
              className="mr-2"
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    );
  };

  const clearAllFilters = () => {
    setEmployeeFilter([]);
    setStatusFilter([]);
    setDeadlineFilter('');
    setPriorityFilter([]);
    setTypeFilter([]);
  };

  const EmployeeFilter = ({ selectedEmployees, onUpdate }: { 
    selectedEmployees: string[], 
    onUpdate: (employees: string[]) => void 
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const selectedEmployeeObjects = allEmployees.filter(emp => selectedEmployees.includes(emp.employee_id));
    const displayText = selectedEmployeeObjects.length > 0
      ? `${selectedEmployeeObjects[0].name}${selectedEmployeeObjects.length > 1 ? ` +${selectedEmployeeObjects.length - 1}` : ''}`
      : 'Select employees';

    return (
      <div className="relative" ref={ref}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full p-2 border rounded text-sm text-left flex justify-between items-center"
        >
          <span>{displayText}</span>
          <ChevronDown className="h-4 w-4" />
        </button>
        {isOpen && (
          <EmployeeSelector
            selectedEmployees={selectedEmployeeObjects}
            onUpdate={(employees) => onUpdate(employees.map(e => e.employee_id))}
            onClose={() => setIsOpen(false)}
            allEmployees={allEmployees}
          />
        )}
      </div>
    );
  };

  const extractDepartmentsFromDecisions = () => {
    const departmentMap = new Map<string, Set<string>>();
    
    decisions.forEach(decision => {
      const dept = decision.department || 'Other';
      const area = decision.area_of_focus || 'Uncategorized';
      
      if (!departmentMap.has(dept)) {
        departmentMap.set(dept, new Set());
      }
      departmentMap.get(dept)?.add(area);
    });

    const extractedDepartments: Department[] = Array.from(departmentMap).map(([name, areas]) => ({
      name,
      areas_of_focus: Array.from(areas).sort()
    }));

    setDepartments(extractedDepartments.sort((a, b) => a.name.localeCompare(b.name)));
  };

  useEffect(() => {
    extractDepartmentsFromDecisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisions]); // This will run whenever decisions change

  const createNewDecision = async (department: string, areaOfFocus: string) => {
    try {
      const companyName = user?.company || 'Kaynes';
      const newDecision: Partial<Decision> = {
        company: companyName,
        inform: [],
        advise: [],
        decide: [],
        execute: [],
        decision_id: `D${Date.now()}`,
        deadline: new Date().toISOString().split('T')[0],
        decision_level: "Routine",
        status: "To-Do",
        decision_priority: "Medium",
        description: "New decision",
        execute_best_practice: false,
        decide_best_practice: false,
        department: department,
        area_of_focus: areaOfFocus,
      };

      const response = await api.post(`/api/decisions/${companyName}/`, newDecision);
      const createdDecision = response.data;

      // Add the created decision to the local state
      setDecisions(prevDecisions => [createdDecision, ...prevDecisions]);

      // Set the new decision in edit mode
      setEditingDescription(createdDecision.id);
      setEditedDescription(createdDecision.description);

      // Close the dialog
      setIsNewDecisionDialogOpen(false);

      console.log("New decision created:", createdDecision);
    } catch (error) {
      console.error('Error creating new decision:', error);
    }
  };

  const toggleFilterExpansion = () => {
    setIsFilterExpanded((prev) => !prev);
  };

  const toggleRowExpansion = (id: number) => {
    setExpandedRows(prev => 
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  console.log('Rendering Plot with data:', sunburstData)

  // Update the groupedDecisions logic
  const groupedDecisions = React.useMemo(() => {
    const groups: GroupedDecisions = {};
    filteredDecisions.forEach(decision => {
      const department = decision.department || 'Other';
      const area = decision.area_of_focus || 'Uncategorized';
      if (!groups[department]) {
        groups[department] = {};
      }
      if (!groups[department][area]) {
        groups[department][area] = [];
      }
      groups[department][area].push(decision);
    });
    return groups;
  }, [filteredDecisions]);

  useEffect(() => {
    fetchDecisions();
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSunburstData();
  }, [fetchSunburstData]);

  return (
    <div className="flex flex-col h-[calc(100vh-8rem)]">
      <div className="flex-grow w-full">
        <div className="h-full flex flex-col w-full">
          <div className="flex justify-between items-center mb-4 sticky top-0 bg-gradient-to-r from-[#FFF5D6] to-[#F2E2A8] z-20 px-6 py-4 border-b border-gray-200">
            <div className="flex items-center space-x-4">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setIsSummaryView(prev => !prev)}
                className="rounded-full"
              >
                {isSummaryView ? <List size={20} /> : <BarChart2 size={20} />}
              </Button>
              {!isSummaryView && (
                <>
                  <CriticalAlertIndicator 
                    onClick={toggleCriticalFilter} 
                    criticalCount={criticalDecisions} 
                    totalCount={decisions.length} 
                  />
                  <Button
                    variant="outline"
                    onClick={() => setIsNewDecisionDialogOpen(true)}
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    New Decision
                  </Button>
                </>
              )}
            </div>
            {!isSummaryView && (
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  <Input
                    type="search"
                    placeholder="Search"
                    className="pl-10 w-64 md:w-[200px] lg:w-[300px]"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={toggleFilterExpansion}
                    className={`rounded-full ${
                      isFilterExpanded ||
                      employeeFilter.length > 0 ||
                      statusFilter.length > 0 ||
                      !!deadlineFilter ||
                      priorityFilter.length > 0 ||
                      typeFilter.length > 0
                        ? 'bg-white text-yellow-800'
                        : 'bg-white text-gray-800'
                    }`}
                  >
                    {isFilterExpanded ? <ChevronRight size={20} /> : <Filter size={20} />}
                  </Button>
                  {isFilterExpanded && (
                    <div className="flex space-x-2">
                      {/* User Filter */}
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              as={Button}
                              variant="ghost"
                              size="icon"
                              className={`rounded-full ${
                                employeeFilter.length > 0 || open
                                  ? 'bg-yellow-100 text-yellow-800'
                                  : 'bg-white text-gray-800'
                              }`}
                            >
                              <User size={20} />
                            </Popover.Button>
                            <Popover.Panel className="absolute z-50 mt-2 w-64 transform left-0 sm:px-0">
                              <Card>
                                <CardContent className="p-2">
                                  <EmployeeFilter
                                    selectedEmployees={employeeFilter}
                                    onUpdate={(selected) => {
                                      setEmployeeFilter(selected);
                                    }}
                                  />
                                </CardContent>
                              </Card>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>

                      {/* Status Filter */}
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              as={Button}
                              variant="ghost"
                              size="icon"
                              className={`rounded-full ${
                                statusFilter.length > 0 || open
                                  ? 'bg-yellow-100 text-yellow-800'
                                  : 'bg-white text-gray-800'
                              }`}
                            >
                              <Flag size={20} />
                            </Popover.Button>
                            <Popover.Panel className="absolute z-50 mt-2 w-64 transform left-0 sm:px-0">
                              <Card>
                                <CardContent className="p-2">
                                  <MultiSelectFilter
                                    options={statusOptions}
                                    selectedOptions={statusFilter}
                                    onChange={(selected) => {
                                      setStatusFilter(selected);
                                    }}
                                  />
                                </CardContent>
                              </Card>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>

                      {/* Deadline Filter */}
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              as={Button}
                              variant="ghost"
                              size="icon"
                              className={`rounded-full ${
                                !!deadlineFilter || open
                                  ? 'bg-yellow-100 text-yellow-800'
                                  : 'bg-white text-gray-800'
                              }`}
                            >
                              <Calendar size={20} />
                            </Popover.Button>
                            <Popover.Panel className="absolute z-50 mt-2 w-64 transform left-0 sm:px-0">
                              <Card>
                                <CardContent className="p-2">
                                  {['Overdue', 'Within 3 days', 'Within 7 days'].map((option) => (
                                    <div key={option} className="flex items-center mb-2">
                                      <input
                                        type="radio"
                                        id={option}
                                        checked={deadlineFilter === option.toLowerCase().replace(/ /g, '')}
                                        onChange={() => setDeadlineFilter(option.toLowerCase().replace(/ /g, ''))}
                                        className="mr-2 h-4 w-4 text-blue-600"
                                      />
                                      <label htmlFor={option} className="text-sm">{option}</label>
                                    </div>
                                  ))}
                                </CardContent>
                              </Card>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>

                      {/* Priority Filter */}
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              as={Button}
                              variant="ghost"
                              size="icon"
                              className={`rounded-full ${
                                priorityFilter.length > 0 || open
                                  ? 'bg-yellow-100 text-yellow-800'
                                  : 'bg-white text-gray-800'
                              }`}
                            >
                              <BarChart2 size={20} />
                            </Popover.Button>
                            <Popover.Panel className="absolute z-50 mt-2 w-64 transform left-0 sm:px-0">
                              <Card>
                                <CardContent className="p-2">
                                  <MultiSelectFilter
                                    options={priorityOptions}
                                    selectedOptions={priorityFilter}
                                    onChange={(selected) => {
                                      setPriorityFilter(selected);
                                    }}
                                  />
                                </CardContent>
                              </Card>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>

                      {/* Type Filter */}
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              as={Button}
                              variant="ghost"
                              size="icon"
                              className={`rounded-full ${
                                typeFilter.length > 0 || open
                                  ? 'bg-yellow-100 text-yellow-800'
                                  : 'bg-white text-gray-800'
                              }`}
                            >
                              <GitBranch size={20} />
                            </Popover.Button>
                            <Popover.Panel className="absolute z-50 mt-2 w-64 transform left-0 sm:px-0">
                              <Card>
                                <CardContent className="p-2">
                                  <MultiSelectFilter
                                    options={decisionLevels}
                                    selectedOptions={typeFilter}
                                    onChange={(selected) => {
                                      setTypeFilter(selected);
                                    }}
                                  />
                                </CardContent>
                              </Card>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>
                    </div>
                  )}
                  <Button variant="ghost" onClick={clearAllFilters}>
                    Clear Filters
                  </Button>
                </div>
              </div>
            )}
          </div>

          {/* Conditional Rendering of Table or Summary View */}
          {isSummaryView ? (
            <div className="flex-grow w-full h-full">
              {sunburstData ? (
                sunburstData.error === "no_employees" ? (
                  <div className="flex justify-center items-center h-full w-full">
                    <div className="text-center">
                      <p className="text-gray-500 text-lg mb-2">{sunburstData.message}</p>
                      <p className="text-gray-400 text-sm">Tag employees to decisions to view the distribution chart</p>
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-full" style={{ height: 'calc(85vh)' }}>
                    <Plot
                      data={sunburstData.data}
                      layout={{
                        ...sunburstData.layout,
                        margin: { t: 10, l: 10, r: 10, b: 90 },
                        autosize: true,
                        paper_bgcolor: 'rgba(0,0,0,0)',
                        plot_bgcolor: 'rgba(0,0,0,0)',
                        font: { size: 14 },
                      }}
                      config={{
                        responsive: true,
                        displayModeBar: false,
                      }}
                      style={{ width: '100%', height: '100%' }}
                      useResizeHandler={true}
                    />
                  </div>
                )
              ) : (
                <div className="flex justify-center items-center h-full w-full">
                  <p className="text-gray-500 text-lg">Loading chart data...</p>
                </div>
              )}
            </div>
          ) : (
            <div className="flex-grow overflow-auto px-6">
              <Accordion 
                type="multiple" 
                value={expandedDepartments} 
                onValueChange={setExpandedDepartments}
                className="w-full"
              >
                {Object.entries(groupedDecisions).map(([department, areas]) => (
                  <AccordionItem 
                    value={department} 
                    key={department}
                    className="mb-4"
                  >
                    <AccordionTrigger className="text-xl font-semibold px-4">
                      {department}
                    </AccordionTrigger>
                    <AccordionContent>
                      {Object.entries(areas).map(([areaOfFocus, decisions]) => (
                        <div 
                          key={areaOfFocus} 
                          className="mb-6 pl-4"
                          style={{ borderLeft: `4px solid ${aofColors[areaOfFocus] || '#e5e7eb'}` }}
                        >
                          <div className="flex justify-between items-center mb-2">
                            <h3 className="text-lg font-medium">{areaOfFocus || 'Uncategorized'} ({decisions.length})</h3>
                          </div>
                          <table className="w-full border-collapse">
                            <thead className="sticky top-0 bg-white shadow-sm z-10">
                              <tr className="border-b border-gray-200">
                                <th className="w-[50%] text-left py-3 px-2 bg-white">
                                  <button className="font-medium text-xs text-gray-500 uppercase tracking-wider" onClick={() => handleSort('description')}>
                                    Decision {sortColumn === 'description' && (sortDirection === 'asc' ? <ChevronUp className="inline ml-2 h-3 w-3" /> : <ChevronDown className="inline ml-2 h-3 w-3" />)}
                                  </button>
                                </th>
                                <th className="w-[10%] text-left py-3 px-2 font-medium text-xs text-gray-500 uppercase tracking-wider bg-white">Inform</th>
                                <th className="w-[10%] text-left py-3 px-2 font-medium text-xs text-gray-500 uppercase tracking-wider bg-white">Advise</th>
                                <th className="w-[10%] text-left py-3 px-2 font-medium text-xs text-gray-500 uppercase tracking-wider bg-white">Decide</th>
                                <th className="w-[10%] text-left py-3 px-2 font-medium text-xs text-gray-500 uppercase tracking-wider bg-white">Execute</th>
                                <th className="w-[5%] text-left py-3 px-2 bg-white">
                                  <button className="font-medium text-xs text-gray-500 uppercase tracking-wider" onClick={() => handleSort('decision_priority')}>
                                    Priority {sortColumn === 'decision_priority' && (sortDirection === 'asc' ? <ChevronUp className="inline ml-2 h-3 w-3" /> : <ChevronDown className="inline ml-2 h-3 w-3" />)}
                                  </button>
                                </th>
                                <th className="w-[5%] text-left py-3 px-2 bg-white">
                                  <button className="font-medium text-xs text-gray-500 uppercase tracking-wider" onClick={() => handleSort('decision_level')}>
                                    Type {sortColumn === 'decision_level' && (sortDirection === 'asc' ? <ChevronUp className="inline ml-2 h-3 w-3" /> : <ChevronDown className="inline ml-2 h-3 w-3" />)}
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {decisions.map((decision) => (
                                <tr 
                                  key={decision.id}
                                  className="border-b border-gray-100 hover:bg-gray-50 transition-colors"
                                >
                                  <td className="py-4 px-2">
                                    <div className="flex items-start">
                                      <button 
                                        className="mr-2 mt-1 focus:outline-none"
                                        onClick={() => toggleRowExpansion(decision.id)}
                                      >
                                      </button>
                                      {editingDescription === decision.id ? (
                                        <textarea
                                          value={editedDescription}
                                          onChange={(e) => setEditedDescription(e.target.value)}
                                          onBlur={() => saveDescription(decision.id)}
                                          className="w-full p-2 border rounded"
                                          rows={3}
                                          autoFocus
                                        />
                                      ) : (
                                        <div className="text-left font-medium max-w-[500px]">
                                          <ExpandableText text={decision.description} />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td className="py-4 px-2 text-center">
                                    <EmployeeCell 
                                      employees={decision.inform} 
                                      onUpdate={(employees) => updateEmployees(decision.id, 'inform', employees)} 
                                      isCritical={false}
                                      decisionId={decision.id}
                                      isExpanded={expandedRows.includes(decision.id)}
                                      allEmployees={allEmployees}
                                    />
                                  </td>
                                  <td className="py-4 px-2 text-center">
                                    <EmployeeCell 
                                      employees={decision.advise} 
                                      onUpdate={(employees) => updateEmployees(decision.id, 'advise', employees)} 
                                      isCritical={false}
                                      decisionId={decision.id}
                                      isExpanded={expandedRows.includes(decision.id)}
                                      allEmployees={allEmployees}
                                    />
                                  </td>
                                  <td className="py-4 px-2 text-center">
                                    <EmployeeCell 
                                      employees={decision.decide} 
                                      onUpdate={(employees) => updateEmployees(decision.id, 'decide', employees)}
                                      isCritical={decision.decide_best_practice}
                                      criticalType="decide"
                                      decisionId={decision.id}
                                      isExpanded={expandedRows.includes(decision.id)}
                                      allEmployees={allEmployees}
                                    />
                                  </td>
                                  <td className="py-4 px-2 text-center">
                                    <EmployeeCell 
                                      employees={decision.execute} 
                                      onUpdate={(employees) => updateEmployees(decision.id, 'execute', employees)}
                                      isCritical={decision.execute_best_practice}
                                      criticalType="execute"
                                      decisionId={decision.id}
                                      isExpanded={expandedRows.includes(decision.id)}
                                      allEmployees={allEmployees}
                                    />
                                  </td>
                                  <td className="py-4 px-2">
                                    <AutoWidthSelect
                                      options={priorityOptions}
                                      value={decision.decision_priority}
                                      onChange={(e) => updateDecision(decision.id, 'decision_priority', e.target.value)}
                                      className={`${priorityColors[decision.decision_priority]} text-sm/7 font-medium`}
                                    />
                                  </td>
                                  <td className="py-4 px-2">
                                    <AutoWidthSelect
                                      options={decisionLevels}
                                      value={decision.decision_level}
                                      onChange={(e) => updateDecision(decision.id, 'decision_level', e.target.value)}
                                      className="bg-transparent text-sm/7 font-medium"
                                    />
                                  </td>
                                  <td className="py-4 px-2">
                                    <div className="flex flex-col space-y-2">
                                      <button
                                        onClick={() => startEditing(decision.id, decision.description)}
                                        className="p-2 text-black hover:bg-white rounded-full transition-colors"
                                      >
                                        <Edit2 className="h-4 w-4" />
                                      </button>
                                      <button
                                        onClick={() => deleteDecision(decision.id)}
                                        className="p-2 text-black hover:bg-white rounded-full transition-colors"
                                      >
                                        <Trash2 className="h-4 w-4" />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          )}
        </div>
      </div>
      <NewDecisionDialog
        isOpen={isNewDecisionDialogOpen}
        onClose={() => setIsNewDecisionDialogOpen(false)}
        departments={departments}
        onCreateDecision={createNewDecision}
      />
    </div>
  );
}
