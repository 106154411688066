import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../api';

interface User {
  role: string;
  employeeId: string | null;
  company: string;
  userId: string;
  hasAcknowledgedKras: boolean;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUser: (updatedUser: User) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    // Check if user is already authenticated (e.g., from localStorage)
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setIsAuthenticated(true);
      setUser(parsedUser);
      console.log('User loaded from localStorage:', parsedUser);
    }
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    try {
      const response = await api.post('/api/accounts/login/', { username, password });

      if (response.status === 200) {
        const data = response.data;
        const user: User = {
          role: data.role,
          employeeId: data.employeeId,
          company: data.company,
          userId: data.userId,
          hasAcknowledgedKras: data.hasAcknowledgedKras
        };
        setIsAuthenticated(true);
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        console.log('User logged in and stored:', user);
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      const response = await api.post('/api/accounts/logout/');

      if (response.status === 200) {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem('user');
        console.log('User logged out and removed from localStorage');
      } else {
        console.error('Logout failed:', response.data);
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const updateUser = (updatedUser: User) => {
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
