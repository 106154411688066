import React, { useState, useEffect, useRef } from 'react';
import { Node } from 'reactflow';
import { X, Edit2, ChevronDown, ChevronRight, User, Briefcase, MapPin, Calendar, Users, Target, Brain, MinusCircle, PlusCircle, Edit3, Trash2 } from 'lucide-react';
import api from '../api';
import { createPortal } from 'react-dom';
import { useFloating, offset, shift, flip } from '@floating-ui/react';
import { Search } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "./ui/alert-dialog";

interface NodeSidebarProps {
  node: Node;
  employeeDetails: any;
  onClose: () => void;
  onUpdate: (data: { name: string; role: string }) => void;
  userId: string;
}

type Goal = {
  id: string;
  title: string;
  progress: number;
  status: "On track" | "At risk" | "Off track";
  dueDate: string;
};

const sampleGoals: Goal[] = [
  {
    id: "1",
    title: "Increase team productivity by 25%",
    progress: 65,
    status: "On track",
    dueDate: "2024-12-31"
  },
  {
    id: "2",
    title: "Complete leadership training program",
    progress: 30,
    status: "At risk",
    dueDate: "2024-09-30"
  },
  {
    id: "3",
    title: "Implement new project management system",
    progress: 80,
    status: "On track",
    dueDate: "2024-06-30"
  }
];

type EmployeeSelectorProps = {
  selectedEmployee: any;
  onUpdate: (employee: any) => void;
  onClose: () => void;
  allEmployees: any[];
};

const EmployeeSelector = ({ selectedEmployee, onUpdate, onClose, allEmployees }: EmployeeSelectorProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const filteredEmployees = allEmployees.filter(
    emp => 
      emp.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      emp.employee_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-white border rounded-md shadow-lg p-2 w-[300px]">
      <div className="relative mb-2">
        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
        <input
          type="text"
          placeholder="Search by name or ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-8 p-2 border rounded text-sm"
          autoComplete="off"
        />
      </div>
      <div className="max-h-60 overflow-y-auto">
        {filteredEmployees.map(employee => (
          <div
            key={employee.id}
            className={`p-2 hover:bg-gray-100 cursor-pointer ${
              selectedEmployee?.id === employee.id ? 'bg-gray-50' : ''
            }`}
            onClick={() => {
              onUpdate(employee);
              onClose();
            }}
          >
            <div className="text-sm font-medium">{employee.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface EditableTextProps {
  value: string;
  onSave: (value: string) => void;
  onCancel?: () => void;
  placeholder?: string;
  forceEdit?: boolean;
}

interface EditableItemProps {
  item: { description: string; id?: string };
  onEdit: (value: string) => void;
  onDelete: () => void;
  children?: React.ReactNode;
  itemId: string;
  editingNewItem: string | null;
  setEditingNewItem: (id: string | null) => void;
}

const EditableText: React.FC<EditableTextProps> = ({ value, onSave, onCancel, placeholder, forceEdit = false }) => {
  const [isEditing, setIsEditing] = useState(forceEdit);
  const [text, setText] = useState(value);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleSave = () => {
    if (text.trim()) {
      onSave(text.trim());
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setText(value);
    setIsEditing(false);
    onCancel?.();
  };

  if (isEditing) {
    return (
      <div className="flex flex-col gap-2">
        <textarea
          ref={inputRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="w-full p-2 border rounded-md text-sm"
          placeholder={placeholder}
          rows={2}
        />
        <div className="flex gap-2">
          <button
            onClick={handleSave}
            className="px-2 py-1 text-xs bg-primary text-primary-foreground rounded-md hover:bg-primary/90"
          >
            Save
          </button>
          <button
            onClick={handleCancel}
            className="px-2 py-1 text-xs border rounded-md hover:bg-muted"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className="group flex items-start gap-2 cursor-pointer p-2 hover:bg-muted/50 rounded-md"
      onClick={() => setIsEditing(true)}
    >
      <span className="flex-grow">{value}</span>
      <Edit3 size={16} className="opacity-0 group-hover:opacity-100 text-muted-foreground" />
    </div>
  );
};

const EditableItem: React.FC<EditableItemProps> = ({ 
  item, 
  onEdit, 
  onDelete, 
  children, 
  itemId,
  editingNewItem,
  setEditingNewItem
}) => {
  const [isEditing, setIsEditing] = useState(editingNewItem === itemId);

  return (
    <div className="group relative bg-muted p-3 rounded-md">
      <div className="flex items-start justify-between">
        <div className="flex-grow pr-16">
          {isEditing || editingNewItem === itemId ? (
            <EditableText
              value={item.description}
              onSave={(value) => {
                onEdit(value);
                setEditingNewItem(null);
                setIsEditing(false);
              }}
              onCancel={() => {
                setEditingNewItem(null);
                setIsEditing(false);
              }}
              placeholder="Enter description..."
              forceEdit={true}
            />
          ) : (
            <div className="p-2">{item.description}</div>
          )}
        </div>
        <div className="absolute right-3 top-3 opacity-0 group-hover:opacity-100 flex items-center gap-1">
          <button
            onClick={() => setIsEditing(true)}
            className="p-1.5 text-muted-foreground hover:text-foreground rounded-md"
            title="Edit"
          >
            <Edit3 size={16} />
          </button>
          <button
            onClick={onDelete}
            className="p-1.5 text-red-600 hover:text-red-700 rounded-md"
            title="Delete"
          >
            <Trash2 size={16} />
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

const createTempId = () => `temp_${Math.random().toString(36).substr(2, 9)}`;

const NodeSidebar: React.FC<NodeSidebarProps> = ({ node, employeeDetails, onClose, onUpdate, userId }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, setName] = useState("");
  const [isKRAsExpanded, setIsKRAsExpanded] = useState(false);
  const [isKPIsExpanded, setIsKPIsExpanded] = useState(false);
  const [sidebarTop, setSidebarTop] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isGoalsExpanded, setIsGoalsExpanded] = useState(false);
  const [isCompetenciesExpanded, setIsCompetenciesExpanded] = useState(false);
  const [isEmployeeSelectorOpen, setIsEmployeeSelectorOpen] = useState(false);
  const [allEmployees, setAllEmployees] = useState<any[]>([]);
  const { user } = useAuth();
  const [localKRAs, setLocalKRAs] = useState<any[]>([]);
  const [editingNewItem, setEditingNewItem] = useState<string | null>(null);
  const [localKPIs, setLocalKPIs] = useState<any[]>([]);
  const [localCompetencies, setLocalCompetencies] = useState<string[]>([]);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(10), shift(), flip()],
  });

  useEffect(() => {
    setName(employeeDetails.employee.name);
  }, [employeeDetails]);

  useEffect(() => {
    const updateSidebarPosition = () => {
      const header = document.querySelector('.app-header');
      const navbar = document.querySelector('nav');
      if (header && navbar) {
        const totalOffset = header.clientHeight;
        setSidebarTop(totalOffset);
      }
    };

    updateSidebarPosition();
    window.addEventListener('resize', updateSidebarPosition);

    // Trigger animation after a short delay
    const timer = setTimeout(() => setIsVisible(true), 50);

    return () => {
      window.removeEventListener('resize', updateSidebarPosition);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        if (!user?.company) return;
        
        const response = await api.get(`/api/employees/${user.company}/`);
        setAllEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };
    fetchEmployees();
  }, [user?.company]);

  useEffect(() => {
    if (employeeDetails?.role?.kras) {
      setLocalKRAs(employeeDetails.role.kras);
    }
  }, [employeeDetails?.role?.kras]);

  useEffect(() => {
    if (employeeDetails?.role?.kpis) {
      setLocalKPIs(employeeDetails.role.kpis);
    }
    if (employeeDetails?.role?.competencies) {
      setLocalCompetencies(employeeDetails.role.competencies);
    }
  }, [employeeDetails?.role?.kpis, employeeDetails?.role?.competencies]);

  useEffect(() => {
    if (employeeDetails?.employee?.competencies) {
      setLocalCompetencies(employeeDetails.employee.competencies);
    }
  }, [employeeDetails?.employee?.competencies]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300); // Wait for the animation to complete before closing
  };

  const handleEmployeeUpdate = async (newEmployee: any) => {
    try {
      const response = await api.post('api/update-employee-role/', {
        roleId: node.id,
        employeeId: newEmployee.employee_id,
        userId: userId,
        previousRole: employeeDetails.role.title,
        employeeName: newEmployee.name,
        newRole: employeeDetails.role.title
      });

      if (response.data && response.data.role) {
        onUpdate({
          name: response.data.role.current_employee.name,
          role: response.data.role.title
        });
        setIsEmployeeSelectorOpen(false);
      }
    } catch (error) {
      console.error('Error updating employee:', error);
    }
  };

  const handleRemoveEmployee = async () => {
    try {
      const response = await api.post('api/remove-employee-role/', {
        roleId: node.id,
        employeeId: employeeDetails.employee.employee_id,
        userId: userId
      });

      if (response.data && response.data.role) {
        onUpdate({
          name: '',
          role: response.data.role.title
        });
        setIsEmployeeSelectorOpen(false);
      }
    } catch (error) {
      console.error('Error removing employee:', error);
    }
  };

  const handleAddKRA = async () => {
    const tempId = createTempId();
    const newKRA = {
      id: tempId,
      description: "New KRA",
      responsibilities: []
    };

    // Optimistically update the UI
    setLocalKRAs(prev => [...prev, newKRA]);
    setEditingNewItem(tempId);

    try {
      const response = await api.post(`/api/kra/`, {
        role: node.id,
        description: "New KRA"
      });

      // Update local state with the real ID
      setLocalKRAs(prev => prev.map(kra => 
        kra.id === tempId ? { ...response.data, responsibilities: [] } : kra
      ));
      setEditingNewItem(response.data.id);
    } catch (error) {
      console.error('Error adding KRA:', error);
      // Remove the temporary KRA on error
      setLocalKRAs(prev => prev.filter(kra => kra.id !== tempId));
      setEditingNewItem(null);
    }
  };

  const handleEditKRA = async (kraId: string, description: string) => {
    const previousKRA = localKRAs.find(kra => kra.id === kraId);
    
    setLocalKRAs(prev => prev.map(kra => 
      kra.id === kraId ? { ...kra, description } : kra
    ));

    try {
      await api.put(`/api/kra/${kraId}/`, {
        description,
        previousDescription: previousKRA?.description,
        userId: userId,
        roleId: node.id
      });
    } catch (error) {
      console.error('Error updating KRA:', error);
      setLocalKRAs(employeeDetails.role.kras);
    }
  };

  const handleDeleteKRA = async (kraId: string) => {
    // Optimistically update the UI
    const previousKRAs = [...localKRAs];
    setLocalKRAs(prev => prev.filter(kra => kra.id !== kraId));

    try {
      await api.delete(`/api/kra/${kraId}/`);
    } catch (error) {
      console.error('Error deleting KRA:', error);
      // Revert on error
      setLocalKRAs(previousKRAs);
    }
  };

  const handleAddResponsibility = async (kraId: string) => {
    const tempId = createTempId();
    const newResp = {
      id: tempId,
      description: "New Responsibility"
    };

    // Optimistically update the UI
    setLocalKRAs(prev => prev.map(kra => 
      kra.id === kraId 
        ? { ...kra, responsibilities: [...(kra.responsibilities || []), newResp] }
        : kra
    ));
    setEditingNewItem(tempId);

    try {
      const response = await api.post(`/api/kra/${kraId}/responsibility/`, {
        description: "New Responsibility"
      });

      // Update with real ID
      setLocalKRAs(prev => prev.map(kra => 
        kra.id === kraId 
          ? {
              ...kra,
              responsibilities: kra.responsibilities.map(resp =>
                resp.id === tempId ? response.data : resp
              )
            }
          : kra
      ));
      setEditingNewItem(response.data.id);
    } catch (error) {
      console.error('Error adding responsibility:', error);
      // Remove temporary item on error
      setLocalKRAs(prev => prev.map(kra => 
        kra.id === kraId 
          ? { ...kra, responsibilities: kra.responsibilities.filter(r => r.id !== tempId) }
          : kra
      ));
      setEditingNewItem(null);
    }
  };

  const handleEditResponsibility = async (kraId: string, index: number, description: string) => {
    try {
      await api.put(`/api/kra/${kraId}/responsibility/${index}/`, { description });
      onUpdate({ name: employeeDetails.employee.name, role: employeeDetails.role.title });
    } catch (error) {
      console.error('Error updating responsibility:', error);
    }
  };

  const handleDeleteResponsibility = async (kraId: string, index: number) => {
    // Optimistically update the UI
    setLocalKRAs(prev => prev.map(kra => 
      kra.id === kraId 
        ? { 
            ...kra, 
            responsibilities: kra.responsibilities.filter((_, i) => i !== index) 
          }
        : kra
    ));

    try {
      await api.delete(`/api/kra/${kraId}/responsibility/${index}/`);
    } catch (error) {
      console.error('Error deleting responsibility:', error);
      // Revert on error
      setLocalKRAs(employeeDetails.role.kras);
    }
  };

  const handleAddKPI = async () => {
    const tempId = createTempId();
    const newKPI = {
      id: tempId,
      description: "New KPI"
    };

    setLocalKPIs(prev => [...prev, newKPI]);
    setEditingNewItem(tempId);

    try {
      const response = await api.post(`/api/kpi/`, {
        role: node.id,
        description: "New KPI"
      });

      setLocalKPIs(prev => prev.map(kpi => 
        kpi.id === tempId ? response.data : kpi
      ));
      setEditingNewItem(response.data.id);
    } catch (error) {
      console.error('Error adding KPI:', error);
      setLocalKPIs(prev => prev.filter(kpi => kpi.id !== tempId));
      setEditingNewItem(null);
    }
  };

  const handleEditKPI = async (kpiId: string, description: string) => {
    const previousKPI = localKPIs.find(kpi => kpi.id === kpiId);
    
    setLocalKPIs(prev => prev.map(kpi => 
      kpi.id === kpiId ? { ...kpi, description } : kpi
    ));

    try {
      await api.put(`/api/kpi/${kpiId}/`, {
        description,
        previousDescription: previousKPI?.description,
        userId: userId,
        roleId: node.id
      });
    } catch (error) {
      console.error('Error updating KPI:', error);
      setLocalKPIs(employeeDetails.role.kpis);
    }
  };

  const handleDeleteKPI = async (kpiId: string) => {
    const previousKPIs = [...localKPIs];
    setLocalKPIs(prev => prev.filter(kpi => kpi.id !== kpiId));

    try {
      await api.delete(`/api/kpi/${kpiId}/`);
    } catch (error) {
      console.error('Error deleting KPI:', error);
      setLocalKPIs(previousKPIs);
    }
  };

  const handleAddCompetency = async () => {
    try {
      const response = await api.post('/api/competency/', {
        employee_id: employeeDetails.employee.employee_id,
        competency: "New Competency"
      });

      if (response.data && response.data.competencies) {
        setLocalCompetencies(response.data.competencies);
        setEditingNewItem(null);
      }
    } catch (error) {
      console.error('Error adding competency:', error);
    }
  };

  const handleEditCompetency = async (index: number, newValue: string) => {
    try {
      const response = await api.put(`/api/competency/${employeeDetails.employee.employee_id}/${index}/`, {
        competency: newValue
      });

      if (response.data && response.data.competencies) {
        setLocalCompetencies(response.data.competencies);
        setEditingNewItem(null);
      }
    } catch (error) {
      console.error('Error updating competency:', error);
    }
  };

  const handleDeleteCompetency = async (index: number) => {
    try {
      const response = await api.delete(`/api/competency/delete/${employeeDetails.employee.employee_id}/${index}/`);

      if (response.data && response.data.competencies) {
        setLocalCompetencies(response.data.competencies);
      }
    } catch (error) {
      console.error('Error deleting competency:', error);
    }
  };

  if (!employeeDetails || !employeeDetails.employee) {
    return (
      <div 
        ref={sidebarRef}
        className={`fixed right-0 w-1/2 shadow-lg overflow-y-auto z-50 transition-all duration-300 ease-in-out ${isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}
        style={{ 
          top: `${sidebarTop}px`,
          height: `calc(100vh - ${sidebarTop}px)`,
          background: 'linear-gradient(to right, #FFF5D6, #F2E2A8)'
        }}
      >
        <div className="sticky top-0 bg-black z-20 px-6 py-4">
          <h2 className="text-xl font-semibold text-white">Vacant Position</h2>
          <button onClick={handleClose} className="text-primary-foreground hover:text-primary-foreground/80">
            <X size={24} />
          </button>
        </div>
        <div className={`p-6 space-y-6 transition-all duration-300 ease-in-out ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
          <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6 space-y-4">
            <div className="space-y-2">
              <label className="text-sm font-medium text-muted-foreground">Position Details</label>
              <div className="flex items-center">
                <button
                  ref={refs.setReference}
                  onClick={() => setIsEmployeeSelectorOpen(!isEmployeeSelectorOpen)}
                  className="flex-grow flex items-center justify-between px-3 py-2 border rounded-md hover:bg-gray-50"
                >
                  <span className="text-lg font-semibold text-red-500 italic">Vacant Position</span>
                  <Edit2 size={18} className="text-muted-foreground" />
                </button>
              </div>
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-muted-foreground">Role</label>
              <div className="flex items-center">
                <span className="flex-grow text-muted-foreground">{employeeDetails.role.title}</span>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div className="flex items-center">
                <Briefcase size={16} className="mr-2 text-muted-foreground" />
                <span>{employeeDetails.role.department}</span>
              </div>
              <div className="flex items-center">
                <MapPin size={16} className="mr-2 text-muted-foreground" />
                <span>{employeeDetails.role.location}</span>
              </div>
              <div className="flex items-center">
                <Users size={16} className="mr-2 text-muted-foreground" />
                <span>{employeeDetails.role.grade}</span>
              </div>
            </div>
          </div>
          
          {isEmployeeSelectorOpen && createPortal(
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className="z-50"
            >
              <EmployeeSelector
                selectedEmployee={null}
                onUpdate={handleEmployeeUpdate}
                onClose={() => setIsEmployeeSelectorOpen(false)}
                allEmployees={allEmployees}
              />
            </div>,
            document.body
          )}
        </div>
      </div>
    );
  }

  return (
    <div 
      ref={sidebarRef}
      className={`fixed right-0 w-1/2 shadow-lg overflow-y-auto z-50 transition-all duration-300 ease-in-out ${
        isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
      }`}
      style={{ 
        top: `${sidebarTop}px`,
        height: `calc(100vh - ${sidebarTop}px)`,
        background: 'linear-gradient(to right, #FFF5D6, #F2E2A8)'
      }}
    >
      <div className="sticky top-0 bg-black z-20 px-6 py-4">
        <h2 className="text-xl font-semibold text-white">Employee Details</h2>
        <button onClick={handleClose} className="text-primary-foreground hover:text-primary-foreground/80">
          <X size={24} />
        </button>
      </div>
      <div className={`p-6 space-y-6 transition-all duration-300 ease-in-out ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
        {/* Business Card Style Employee Details */}
        <div className="bg-background border border-black rounded-lg shadow-sm p-6 space-y-4">
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-medium text-muted-foreground">Name</label>
            <div className="flex items-center">
              <button
                ref={refs.setReference}
                onClick={() => setIsEmployeeSelectorOpen(!isEmployeeSelectorOpen)}
                className="flex-grow flex items-center justify-between px-3 py-2 border rounded-md hover:bg-gray-50 mr-2"
              >
                <span className="text-lg font-semibold">{employeeDetails.employee.name}</span>
                <Edit2 size={18} className="text-muted-foreground" />
              </button>
              
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <button
                    className="p-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md"
                    title="Remove employee from role"
                  >
                    <MinusCircle size={20} />
                  </button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Remove Employee from Role</AlertDialogTitle>
                    <AlertDialogDescription>
                      Are you sure you want to remove {employeeDetails.employee.name} from the role of {employeeDetails.role.title}? 
                      This will mark the position as vacant.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction 
                      onClick={handleRemoveEmployee}
                      className="bg-red-600 hover:bg-red-700 text-white"
                    >
                      Remove
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </div>
          <div className="space-y-2">
            <label htmlFor="role" className="text-sm font-medium text-muted-foreground">Role</label>
            <div className="flex items-center">
              <span className="flex-grow text-muted-foreground">{employeeDetails.role.title}</span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div className="flex items-center">
              <User size={16} className="mr-2 text-muted-foreground" />
              <span>{employeeDetails.employee.employee_id}</span>
            </div>
            <div className="flex items-center">
              <Briefcase size={16} className="mr-2 text-muted-foreground" />
              <span>{employeeDetails.role.department}</span>
            </div>
            <div className="flex items-center">
              <MapPin size={16} className="mr-2 text-muted-foreground" />
              <span>{employeeDetails.role.location}</span>
            </div>
            <div className="flex items-center">
              <Users size={16} className="mr-2 text-muted-foreground" />
              <span>{employeeDetails.role.grade}</span>
            </div>
            <div className="flex items-center col-span-2">
              <Calendar size={16} className="mr-2 text-muted-foreground" />
              <span>Joined: {employeeDetails.employee.date_of_joining && new Date(employeeDetails.employee.date_of_joining).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center col-span-2">
              <Users size={16} className="mr-2 text-muted-foreground" />
              <span>Manager: {employeeDetails.role.reporting_to?.current_employee?.name || ''}</span>
            </div>
          </div>
        </div>

        {/* KRAs Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <button 
              className="flex items-center text-lg font-semibold w-full"
              onClick={() => setIsKRAsExpanded(!isKRAsExpanded)}
            >
              {isKRAsExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              <span className="ml-2">Key Result Areas (KRAs)</span>
            </button>
            <button
              onClick={handleAddKRA}
              className="p-1 hover:bg-muted rounded-md"
              title="Add KRA"
            >
              <PlusCircle size={20} />
            </button>
          </div>
          {isKRAsExpanded && (
            <div className="space-y-4">
              {localKRAs?.map((kra: any) => (
                <div key={kra.id} className="space-y-2">
                  <EditableItem
                    item={kra}
                    itemId={kra.id}
                    onEdit={(description) => handleEditKRA(kra.id, description)}
                    onDelete={() => handleDeleteKRA(kra.id)}
                    editingNewItem={editingNewItem}
                    setEditingNewItem={setEditingNewItem}
                  >
                    {kra.responsibilities?.length > 0 && (
                      <>
                        <div className="mt-3 mb-2 border-t border-border/50" />
                        <div className="space-y-2">
                          <p className="text-xs font-medium text-muted-foreground ml-2">
                            Responsibilities:
                          </p>
                          <div className="pl-3 space-y-2">
                            {kra.responsibilities.map((resp: any, index: number) => (
                              <EditableItem
                                key={resp.id || index}
                                item={resp}
                                itemId={resp.id}
                                onEdit={(description) => handleEditResponsibility(kra.id, index, description)}
                                onDelete={() => handleDeleteResponsibility(kra.id, index)}
                                editingNewItem={editingNewItem}
                                setEditingNewItem={setEditingNewItem}
                              />
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    <button
                      onClick={() => handleAddResponsibility(kra.id)}
                      className="mt-2 flex items-center gap-1 text-xs text-muted-foreground hover:text-foreground ml-3"
                    >
                      <PlusCircle size={14} />
                      <span>Add Responsibility</span>
                    </button>
                  </EditableItem>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* KPIs Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <button 
              className="flex items-center text-lg font-semibold w-full"
              onClick={() => setIsKPIsExpanded(!isKPIsExpanded)}
            >
              {isKPIsExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              <span className="ml-2">Key Performance Indicators (KPIs)</span>
            </button>
            <button
              onClick={handleAddKPI}
              className="p-1 hover:bg-muted rounded-md"
              title="Add KPI"
            >
              <PlusCircle size={20} />
            </button>
          </div>
          {isKPIsExpanded && (
            <div className="space-y-4">
              {localKPIs?.map((kpi) => (
                <EditableItem
                  key={kpi.id}
                  item={kpi}
                  itemId={kpi.id}
                  onEdit={(description) => handleEditKPI(kpi.id, description)}
                  onDelete={() => handleDeleteKPI(kpi.id)}
                  editingNewItem={editingNewItem}
                  setEditingNewItem={setEditingNewItem}
                />
              ))}
            </div>
          )}
        </div>

        {/* Decisions Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-4">Decisions</h3>
          {employeeDetails.decisions && employeeDetails.decisions.length > 0 ? (
            <ul className="space-y-2">
              {employeeDetails.decisions.map((decision: any, index: number) => (
                <li key={index} className="bg-muted p-3 rounded-md">
                  <p className="font-medium text-sm">{decision.description}</p>
                  <p className="text-xs text-muted-foreground mt-1">Status: {decision.status}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted-foreground">No decisions available</p>
          )}
        </div>

        {/* Goals Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <button 
            className="flex items-center text-lg font-semibold mb-4 w-full"
            onClick={() => setIsGoalsExpanded(!isGoalsExpanded)}
          >
            {isGoalsExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
            <Target size={20} className="ml-2 mr-2" />
            <span>Goals</span>
          </button>
          {isGoalsExpanded && (
            <div className="space-y-4">
              {sampleGoals.map((goal) => (
                <div key={goal.id} className="bg-muted p-4 rounded-md space-y-2">
                  <div className="flex justify-between items-start">
                    <h4 className="font-medium">{goal.title}</h4>
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      goal.status === "On track" ? "bg-green-100 text-green-800" :
                      goal.status === "At risk" ? "bg-yellow-100 text-yellow-800" :
                      "bg-red-100 text-red-800"
                    }`}>
                      {goal.status}
                    </span>
                  </div>
                  <div className="space-y-1">
                    <div className="w-full bg-background rounded-full h-2">
                      <div 
                        className="bg-primary h-2 rounded-full transition-all"
                        style={{ width: `${goal.progress}%` }}
                      />
                    </div>
                    <div className="flex justify-between text-sm text-muted-foreground">
                      <span>{goal.progress}% complete</span>
                      <span>Due: {new Date(goal.dueDate).toLocaleDateString()}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Competencies Section */}
        <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <button 
              className="flex items-center text-lg font-semibold w-full"
              onClick={() => setIsCompetenciesExpanded(!isCompetenciesExpanded)}
            >
              {isCompetenciesExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              <Brain size={20} className="ml-2 mr-2" />
              <span>Competencies</span>
            </button>
            <button
              onClick={handleAddCompetency}
              className="p-1 hover:bg-muted rounded-md"
              title="Add Competency"
            >
              <PlusCircle size={20} />
            </button>
          </div>
          {isCompetenciesExpanded && (
            <div className="space-y-4">
              {localCompetencies?.map((competency, index) => (
                <EditableItem
                  key={index}
                  item={{ description: competency, id: index.toString() }}
                  itemId={index.toString()}
                  onEdit={(description) => handleEditCompetency(index, description)}
                  onDelete={() => handleDeleteCompetency(index)}
                  editingNewItem={editingNewItem}
                  setEditingNewItem={setEditingNewItem}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Move the portal outside the main content but still inside the sidebar */}
      {isEmployeeSelectorOpen && createPortal(
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="z-[100]" // Increased z-index
        >
          <EmployeeSelector
            selectedEmployee={employeeDetails.employee}
            onUpdate={handleEmployeeUpdate}
            onClose={() => setIsEmployeeSelectorOpen(false)}
            allEmployees={allEmployees}
          />
        </div>,
        document.body
      )}
    </div>
  );
};

export default NodeSidebar;
