import React from 'react';
import { EdgeProps } from 'reactflow';

const CustomEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  markerEnd,
  data,
}) => {
  // Constants for edge rendering
  const NODE_WIDTH = 220;
  const VERTICAL_MARGIN = 30;
  const EDGE_RADIUS = 20;

  const getHierarchicalPath = () => {
    const isIC = data?.isVerticalIC;
    
    // Calculate the y-coordinate for the horizontal "vein"
    const horizontalY = sourceY + VERTICAL_MARGIN / 2;
    
    if (isIC) {
      // For IC nodes, create a path with rounded corners
      // Direction of curve depends on whether we're going right or left
      const goingRight = targetX > sourceX;
      const curveDirection = goingRight ? 1 : -1;
      
      return `
        M ${sourceX} ${sourceY}
        L ${sourceX} ${horizontalY - EDGE_RADIUS}
        Q ${sourceX} ${horizontalY} ${sourceX + (EDGE_RADIUS * curveDirection)} ${horizontalY}
        L ${targetX - NODE_WIDTH/4 - (EDGE_RADIUS * curveDirection)} ${horizontalY}
        Q ${targetX - NODE_WIDTH/4} ${horizontalY} ${targetX - NODE_WIDTH/4} ${horizontalY + EDGE_RADIUS}
        L ${targetX - NODE_WIDTH/4} ${targetY - EDGE_RADIUS}
        Q ${targetX - NODE_WIDTH/4} ${targetY} ${targetX - NODE_WIDTH/4 + EDGE_RADIUS} ${targetY}
        L ${targetX} ${targetY}
      `;
    } else {
      // For manager nodes, create a path with rounded corners
      // Direction of curve depends on whether we're going right or left
      const goingRight = targetX > sourceX;
      const curveDirection = goingRight ? 1 : -1;
      
      return `
        M ${sourceX} ${sourceY}
        L ${sourceX} ${horizontalY - EDGE_RADIUS}
        Q ${sourceX} ${horizontalY} ${sourceX + (EDGE_RADIUS * curveDirection)} ${horizontalY}
        L ${targetX - (EDGE_RADIUS * curveDirection)} ${horizontalY}
        Q ${targetX} ${horizontalY} ${targetX} ${horizontalY + EDGE_RADIUS}
        L ${targetX} ${targetY}
      `;
    }
  };

  return (
    <path
      id={id}
      style={{
        ...style,
        strokeWidth: 2,
        stroke: '#555',
        fill: 'none',
      }}
      className="react-flow__edge-path"
      d={getHierarchicalPath()}
      markerEnd={markerEnd}
    />
  );
};

export default CustomEdge;
