"use client"

import { useState, useEffect } from 'react';
import { Search, Flag, Brain } from 'lucide-react';
import { useKPI } from '../contexts/KPIContext';
import { cn } from "../lib/utils";
import { useAuth } from '../contexts/AuthContext';
import api from '../api';

import { Button } from "./ui/button"
import { Input } from "./ui/input"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion"

const ReviewIndicator = ({ itemsForReview, isActive, onClick }: {
  itemsForReview: number;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      variant={isActive ? "secondary" : "outline"}
      size="sm"
      onClick={onClick}
      className={cn(
        "flex items-center space-x-2",
        isActive && "bg-yellow-100 hover:bg-yellow-200 text-yellow-900"
      )}
    >
      <Flag className="h-4 w-4" />
      <span>{itemsForReview} for review</span>
    </Button>
  );
};

const renderCompetencies = (competencies: string[]) => {
  if (!competencies || competencies.length === 0) return null;
  
  return (
    <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
      <div className="flex items-center mb-4">
        <Brain size={20} className="mr-2" />
        <h5 className="font-medium text-lg">Competencies</h5>
      </div>
      <div className="space-y-4">
        {competencies.map((competency, index) => (
          <div key={index} className="bg-muted p-3 rounded-md">
            <span className="flex-grow">{competency}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function KPIs() {
  const { departments, updateDepartments } = useKPI();
  const [searchTerm, setSearchTerm] = useState('');
  const [reviewFilter, setReviewFilter] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('All');
  const { user } = useAuth();

  // Add this effect to refresh data when component mounts
  useEffect(() => {
    const fetchFreshData = async () => {
      try {
        const companyName = user?.company || 'Kaynes';
        const response = await api.get(`/api/company/${companyName}/`);
        const { departments: freshData } = response.data;
        updateDepartments(freshData);
      } catch (error) {
        console.error('Error fetching fresh KPI data:', error);
      }
    };

    fetchFreshData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Calculate items for review
  const itemsForReview = departments.flatMap(dept => dept.roles).reduce((acc, role) => {
    return acc + 
      role.kpis.filter(kpi => kpi.needs_review).length +
      role.kras.filter(kra => kra.needs_review).length;
  }, 0);

  // Filter roles based on search and review filter
  const filteredRoles = departments.flatMap(dept => dept.roles)
    .filter(role => {
      if (selectedDepartment !== 'All' && role.department !== selectedDepartment) {
        return false;
      }

      const nameMatch = role.current_employee && 
        role.current_employee.name.toLowerCase().includes(searchTerm.toLowerCase());
      const titleMatch = role.title.toLowerCase().includes(searchTerm.toLowerCase());
      const kpiMatch = role.kpis.some(kpi => 
        kpi.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const kraMatch = role.kras.some(kra => 
        kra.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const searchMatches = nameMatch || titleMatch || kpiMatch || kraMatch;

      const reviewMatches = !reviewFilter || 
        role.kpis.some(kpi => kpi.needs_review) || 
        role.kras.some(kra => kra.needs_review);

      return searchMatches && reviewMatches;
    });

  const renderSection = (title: string, items: any[], needsReview = false) => {
    if (!items || items.length === 0) return null;
    
    return (
      <div className="bg-background border border-black rounded-lg shadow-sm p-6">
        <h5 className="font-medium text-lg mb-4">{title}</h5>
        <div className="space-y-4">
          {items.map((item) => (
            <div key={item.id} className="bg-muted p-3 rounded-md">
              <div className="flex items-center justify-between">
                <span className={cn(
                  "flex-grow",
                  needsReview && item.needs_review && "text-yellow-600"
                )}>
                  {item.description || item.title}
                </span>
                {needsReview && item.needs_review && (
                  <Flag className="h-4 w-4 text-yellow-500 ml-2" />
                )}
                {item.status && (
                  <span className={cn(
                    "px-2 py-1 rounded-full text-xs",
                    item.status === "On track" ? "bg-green-100 border border-black text-black" :
                    item.status === "At risk" ? "bg-yellow-100 border border-black text-black" :
                    "bg-red-100 border border-black text-black"
                  )}>
                    {item.status}
                  </span>
                )}
              </div>
              {item.responsibilities && item.responsibilities.length > 0 && (
                <div className="mt-3 pl-4 space-y-2">
                  <h6 className="text-sm font-medium text-muted-foreground mb-2">Responsibilities:</h6>
                  {item.responsibilities.map((resp: any) => (
                    <div key={resp.id} className="text-sm pl-2 border-l-2 border-muted-foreground/20">
                      {resp.description}
                    </div>
                  ))}
                </div>
              )}
              {item.progress !== undefined && (
                <div className="mt-2 space-y-1">
                  <div className="w-full bg-background rounded-full h-2">
                    <div 
                      className="bg-primary h-2 rounded-full transition-all"
                      style={{ width: `${item.progress}%` }}
                    />
                  </div>
                  <div className="flex justify-between text-sm text-muted-foreground">
                    <span>{item.progress}% complete</span>
                    <span>Due: {new Date(item.due_date).toLocaleDateString()}</span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-[calc(100vh-8rem)]">
      <div className="bg-background border-b border-black p-4">
        <div className="flex items-center space-x-2">
          <ReviewIndicator 
            itemsForReview={itemsForReview}
            isActive={reviewFilter}
            onClick={() => setReviewFilter(!reviewFilter)}
          />
        </div>
        
        <div className="flex items-center mt-4">
          <div className="flex-grow relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-white" />
            <Input
              type="search"
              placeholder="Search employees, roles, KRAs, or KPIs"
              className="pl-10 w-full opacity-70 text-white"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <select
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            className="ml-4 w-[180px] p-2 border opacity-70 border-input rounded-md"
          >
            <option value="All">All Departments</option>
            {departments.map(dept => (
              <option key={dept.name} value={dept.name}>{dept.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex-1 overflow-auto">
        <Accordion type="single" collapsible className="space-y-2">
          {filteredRoles.map(role => (
            <AccordionItem key={role.id} value={role.id.toString()} className="border rounded-md">
              <AccordionTrigger className="px-4 py-2 hover:bg-muted/50">
                <div className="flex items-center justify-between w-full">
                  <div className="flex-grow text-left">
                    <h3 className="text-base font-semibold">
                      {role.current_employee ? role.current_employee.name : 'TBH'}
                    </h3>
                    <p className="text-sm text-muted-foreground">
                      {role.title} • {role.department}
                    </p>
                  </div>
                  {(role.kpis.some(kpi => kpi.needs_review) || role.kras.some(kra => kra.needs_review)) && (
                    <Flag className="h-4 w-4 text-yellow-500 ml-2" />
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-4 py-2 space-y-4">
                {/* Employee Details Card */}
                <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6 space-y-4">
                  <div className="grid grid-cols-2 gap-4 text-sm">
                    {role.current_employee && (
                      <>
                        <div className="flex items-center">
                          <span className="text-muted-foreground mr-2">ID:</span>
                          <span>{role.current_employee.employee_id}</span>
                        </div>
                        <div className="flex items-center">
                          <span className="text-muted-foreground mr-2">Email:</span>
                          <span>{role.current_employee.email}</span>
                        </div>
                        <div className="flex items-center">
                          <span className="text-muted-foreground mr-2">Location:</span>
                          <span>{role.location}</span>
                        </div>
                        <div className="flex items-center">
                          <span className="text-muted-foreground mr-2">Grade:</span>
                          <span>{role.grade}</span>
                        </div>
                        {role.current_employee.date_of_joining && (
                          <div className="flex items-center col-span-2">
                            <span className="text-muted-foreground mr-2">Joined:</span>
                            <span>{new Date(role.current_employee.date_of_joining).toLocaleDateString()}</span>
                          </div>
                        )}
                        {role.reporting_to?.current_employee && (
                          <div className="flex items-center col-span-2">
                            <span className="text-muted-foreground mr-2">Reports to:</span>
                            <span>{role.reporting_to.current_employee.name}</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {/* KRAs Section */}
                {renderSection('Key Result Areas (KRAs)', role.kras, true)}
                
                {/* KPIs Section */}
                {renderSection('Key Performance Indicators (KPIs)', role.kpis, true)}
                
                {/* Goals Section */}
                {renderSection('Goals', role.goals)}
                
                {/* Decisions Section */}
                {role.decisions && role.decisions.length > 0 && (
                  <div className="bg-card text-card-foreground rounded-lg shadow-sm p-6">
                    <h3 className="text-lg font-semibold mb-4">Decisions</h3>
                    <div className="space-y-2">
                      {role.decisions.map((decision) => (
                        <div key={decision.id} className="bg-muted p-3 rounded-md">
                          <p className="font-medium text-sm">{decision.description}</p>
                          <div className="flex justify-between items-center mt-1">
                            <p className="text-xs text-muted-foreground">Status: {decision.status}</p>
                            <p className="text-xs text-muted-foreground">
                              {new Date(decision.date).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Competencies Section */}
                {role.current_employee?.competencies && 
                  renderCompetencies(role.current_employee.competencies)}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
