import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip';

interface Employee {
  id: string;
  name: string;
  email: string;
  employee_code: string;
}

interface SecondaryManager {
  id: string;
  name: string;
  title: string;
  department: string;
  employee_code: string;
}

interface CustomNodeData {
  title: string;
  department: string;
  color: string;
  isIc: boolean;
  grade: string;
  location: string;
  currentEmployee: Employee | null;
  secondaryManager: SecondaryManager | null;
  width: number;
}

const CustomNode: React.FC<NodeProps<CustomNodeData>> = ({ data, id }) => {
  // Add this console log to verify data
  console.log('Node render:', id, {
    hasSecondaryManager: !!data.secondaryManager,
    secondaryManager: data.secondaryManager
  });

  // Add debug logging
  console.log('Node data:', id, data);
  console.log('Secondary manager:', data.secondaryManager);

  // Check if this is the virtual root node
  const isVirtualRoot = id === 'virtual_root';

  if (isVirtualRoot) {
    return (
      <div 
        style={{ 
          background: '#f8f9fa',
          border: '2px solid #dee2e6',
          borderRadius: '8px',
          padding: '12px',
          width: '220px',
          minHeight: '60px', // Smaller height for company node
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
          fontFamily: 'Inter, sans-serif',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#495057',
          textAlign: 'center',
        }}>
          {data.title}
        </div>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: '#555',
            width: '8px',
            height: '8px',
          }}
        />
      </div>
    );
  }

  // Regular node rendering
  return (
    <div 
      style={{ 
        background: 'white',
        borderTop: `2px solid ${data.color}`,
        borderRadius: '3px',
        padding: '16px',
        width: '220px',
        height: '110px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Inter, sans-serif',
        position: 'relative',
        marginLeft: data.isIc ? '20px' : '0px',
        overflow: 'visible', // Changed from 'hidden' to show the stem
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Secondary Reporting Line - Moved outside main content */}
      {data.secondaryManager && (
        <div
          style={{
            position: 'absolute',
            top: '-30px',
            right: '20px',
            width: '2px',
            height: '30px',
            background: 'transparent',
            borderLeft: '3px dotted #ff4444',
            zIndex: 1,
          }}
        >
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                {/* Dot at the end of the line - Made larger and centered */}
                <div
                  style={{
                    position: 'absolute',
                    top: '-8px',
                    left: '-7px',
                    width: '14px',
                    height: '14px',
                    borderRadius: '50%',
                    backgroundColor: '#ff4444',
                    border: '2px solid #fff',
                    boxShadow: '0 0 4px rgba(0,0,0,0.2)',
                    zIndex: 2,
                    cursor: 'pointer',
                  }}
                />
              </TooltipTrigger>
              <TooltipContent 
                side="right" 
                className="bg-white rounded-lg shadow-lg border border-gray-200 p-3 z-50"
                style={{
                  backgroundColor: 'white',
                  padding: '12px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  border: '1px solid #e2e8f0',
                  maxWidth: '250px',
                  zIndex: 1000,
                }}
              >
                <div className="space-y-2">
                  <div className="font-semibold text-gray-900 border-b pb-1 mb-2">
                    Dotted Line Manager
                  </div>
                  <div className="text-gray-800 font-medium">
                    {data.secondaryManager.name}
                  </div>
                  <div className="text-gray-600">
                    {data.secondaryManager.title}
                  </div>
                  <div className="text-gray-500 text-sm">
                    {data.secondaryManager.department}
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}

      {/* Main content */}
      <div>
        {/* Employee Section */}
        {data.currentEmployee ? (
          <div style={{ marginBottom: '4px' }}>
            <div style={{ 
              fontSize: '20px',
              fontWeight: '600',
              color: '#1a1a1a',
              marginBottom: '1px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
              {data.currentEmployee.name}
            </div>
          </div>
        ) : (
          <div style={{ 
            fontSize: '20px',
            color: '#dc3545',
            fontStyle: 'italic',
            marginBottom: '1px',
          }}>
            Vacant Position
          </div>
        )}

        {/* Role Title */}
        <div style={{ 
          fontSize: '17px', 
          fontWeight: '400',
          color: '#6c757d',
          marginBottom: '1px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          {data.title}
        </div>

        {/* Department */}
        <div style={{ 
          fontSize: '14px', 
          color: '#95a5a6',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginBottom: '2px'
        }}>
          {data.department}
        </div>
      </div>

      {/* Regular Handles */}
      {data.isIc ? (
        <Handle
          type="target"
          position={Position.Left}
          style={{
            background: '#555',
            width: '8px',
            height: '8px',
          }}
        />
      ) : (
        <>
          <Handle
            type="target"
            position={Position.Top}
            style={{
              background: '#555',
              width: '8px',
              height: '8px',
            }}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            style={{
              background: '#555',
              width: '8px',
              height: '8px',
            }}
          />
        </>
      )}
    </div>
  );
};

export default memo(CustomNode);
