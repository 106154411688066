/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, {
  Node,
  Edge,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  XYPosition,
  ReactFlowProvider,
  ReactFlowInstance,
} from 'reactflow';
import 'reactflow/dist/style.css';
import api from '../api';
import { useAuth } from '../contexts/AuthContext'; // Add this import
import EditHistory from './EditHistory';

import CustomNode from './CustomNode';
import CustomEdge from './CustomEdge';
import NodeSidebar from './NodeSidebar';
import KPIs from './KPIs';
import ViewNavigation from './ViewNavigation';
import { Button } from "./ui/button";
import { ArrowUpCircle } from "lucide-react";

// Define nodeTypes outside of the component
const nodeTypes = {
  custom: CustomNode,
};

// Define edgeTypes outside of the component
const edgeTypes = {
  custom: CustomEdge,
};

const AltOrgChart: React.FC = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [employeeDetails, setEmployeeDetails] = useState<{
    employee: {
      employee_name: string;
      title: string;
    };
  } | null>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user } = useAuth();

  // Remove isInitialRender ref as we don't need it anymore
  const initialViewport = { x: 0, y: 0, zoom: 0.5 };
  const [currentView, setCurrentView] = useState<'chart' | 'details' | 'history'>('chart');
  const [showHistory, setShowHistory] = useState(false);
  const [showKPIs, setShowKPIs] = useState(false);
  const [showCenterButton, setShowCenterButton] = useState(false);

  // Simplify data fetching to a single useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyName = user?.company || 'Kaynes';
        const response = await api.get(`/api/org-chart/${companyName}`);
        const { nodes: apiNodes, edges: apiEdges } = response.data;

        const transformedNodes = apiNodes.map((node: any) => ({
          id: node.id.toString(),
          type: 'custom',
          position: { x: node.position.x, y: node.position.y },
          data: {
            title: node.data.title,
            department: node.data.department,
            color: node.data.color,
            isIc: node.data.isIc,
            width: node.data.width,
            currentEmployee: node.data.currentEmployee,
            secondaryManager: node.data.secondaryManager,
          },
        }));

        console.log('Transformed nodes:', transformedNodes);

        const transformedEdges = apiEdges.map((edge: any) => ({
          id: edge.id,
          source: edge.source.toString(),
          target: edge.target.toString(),
          type: 'custom',
          data: { isVerticalIC: edge.data.isVerticalIC },
        }));

        setNodes(transformedNodes);
        setEdges(transformedEdges);

        // Set initial viewport once data is loaded
        if (reactFlowInstance && transformedNodes.length > 0) {
          const topNode = transformedNodes[0];
          reactFlowInstance.setViewport({
            x: topNode.position.x + 250,
            y: topNode.position.y + 50,
            zoom: 0.9,
          });
        }
      } catch (error) {
        console.error('Error fetching org chart data:', error);
      }
    };

    fetchData();
  }, [user?.company]); // Only depend on company change

  // Simplify the onNodeDoubleClick handler
  const onNodeDoubleClick = useCallback(async (event: React.MouseEvent, node: Node) => {
    event.preventDefault();
    try {
      // If there's no current employee, create a simplified employee details object
      if (!node.data.currentEmployee) {
        setEmployeeDetails({
          employee: {
            name: '',
            employee_id: '',
            email: '',
            date_of_joining: null,
            is_active: true
          },
          role: {
            id: node.id,
            title: node.data.title,
            department: node.data.department,
            location: node.data.location,
            grade: node.data.grade,
            reporting_to: node.data.reporting_to,
            kras: [],
            kpis: [],
            current_employee: null
          }
        });
        setSelectedNode(node);
        setSidebarOpen(true);
        return;
      }

      // Normal flow for roles with employees
      const response = await api.get(`/api/employee-dashboard/${node.data.currentEmployee.employee_code}/`);
      setEmployeeDetails(response.data);
      setSelectedNode(node);
      setSidebarOpen(true);
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  }, []);

  const onRemoveNode = useCallback(
    (id: string) => {
      setNodes((nds) => nds.filter((n) => n.id !== id));
      setEdges((eds) => eds.filter((e) => e.source !== id && e.target !== id));
    },
    [setNodes, setEdges]
  );

  const onDeleteNode = useCallback(
    async (id: string) => {
      try {
        const nodeToDelete = nodes.find(node => node.id === id);
        if (!nodeToDelete) return;

        const parentEdge = edges.find(edge => edge.target === id);
        const childEdges = edges.filter(edge => edge.source === id);
        const updatedEdges = edges.filter(edge => edge.source !== id && edge.target !== id);
        
        if (parentEdge) {
          childEdges.forEach(childEdge => {
            updatedEdges.push({
              ...childEdge,
              source: parentEdge.source
            });
          });
        }

        setNodes(nodes => nodes.filter(node => node.id !== id));
        setEdges(updatedEdges);

        await api.post('/api/delete-node/', { nodeId: id });
      } catch (error) {
        console.error('Error deleting node:', error);
      }
    },
    [nodes, edges, setNodes, setEdges]
  );

  // 2. Create a stable reference for node data creation
  const createNodeData = useCallback((
    name: string,
    title: string,
    department: string,
    color: string,
    isIc: boolean,
    width: number,
    handlers: {
      onAddChild: (parentId: string) => Promise<void>;
      onRemoveNode: (id: string) => void;
      onDeleteNode: (id: string) => Promise<void>;
    }
  ) => ({
    name,
    title,
    department,
    color,
    isIc,
    width,
    ...handlers
  }), []);

  // 3. Define onAddChild
  const onAddChild = useCallback(
    async (parentId: string) => {
      try {
        const response = await api.post('/api/create-node/', {
          node: {
            parentId: parentId,
            employee_name: 'New Employee',
            title: 'New Title',
            department: 'Department'
          }
        });

        const createdNode = response.data.node;
        const parentNode = nodes.find(node => node.id === parentId);

        if (!parentNode) {
          console.error('Parent node not found');
          return;
        }

        const newNode: Node = {
          id: createdNode.id.toString(),
          type: 'custom',
          position: { x: parentNode.position.x, y: parentNode.position.y + 100 },
          data: createNodeData(
            createdNode.employee_name || 'New Employee',
            createdNode.title || 'New Title',
            createdNode.department || 'Department',
            createdNode.color || '#ffffff',
            createdNode.is_ic || false,
            createdNode.width,
            { onAddChild, onRemoveNode, onDeleteNode }
          ),
        };

        setNodes(currentNodes => [...currentNodes, newNode]);
        setEdges(currentEdges => [
          ...currentEdges,
          {
            id: `e${parentId}-${createdNode.id}`,
            source: parentId,
            target: createdNode.id.toString(),
            type: 'custom'
          }
        ]);

      } catch (error) {
        console.error('Error creating node:', error);
      }
    },
    [nodes, setNodes, setEdges, createNodeData, onRemoveNode, onDeleteNode]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const updateChartSize = () => {
      const header = document.querySelector('.app-header');
      const headerHeight = header?.offsetHeight || 0;
      const windowHeight = window.innerHeight;
      const availableHeight = windowHeight - headerHeight;
      
      const chartContainer = document.getElementById('org-chart-container');
      if (chartContainer) {
        chartContainer.style.height = `${availableHeight}px`;
      }
    };

    // Call immediately and set up listener
    updateChartSize();
    window.addEventListener('resize', updateChartSize);

    return () => {
      window.removeEventListener('resize', updateChartSize);
    };
  }, []);

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      const reactFlowBounds = document.querySelector('.react-flow')?.getBoundingClientRect();
      const draggedNodeId = event.dataTransfer.getData('application/reactflow');
      const targetNodeId = (event.target as HTMLElement).closest('.react-flow__node')?.getAttribute('data-id');

      if (draggedNodeId && targetNodeId && draggedNodeId !== targetNodeId && reactFlowBounds) {
        const draggedNode = nodes.find((node) => node.id === draggedNodeId);
        const targetNode = nodes.find((node) => node.id === targetNodeId);

        if (draggedNode && targetNode) {
          // Remove existing edge from dragged node's parent
          const updatedEdges = edges.filter(
            (edge) => edge.target !== draggedNodeId
          );

          // Add new edge connecting target node to dragged node
          const newEdge: Edge = {
            id: `e${targetNodeId}-${draggedNodeId}`,
            source: targetNodeId,
            target: draggedNodeId,
          };

          setEdges([...updatedEdges, newEdge]);

          // Update node positions
          const updatedNodes = nodes.map((node) => {
            if (node.id === draggedNodeId) {
              const newPosition: XYPosition = {
                x: targetNode.position.x,
                y: targetNode.position.y + 100,
              };
              return {
                ...node,
                position: newPosition,
              };
            }
            return node;
          });

          setNodes(updatedNodes);

          // Update backend
          updateHierarchyOnBackend(draggedNodeId, targetNodeId);
        }
      }
    },
    [nodes, edges, setNodes, setEdges]
  );

  const updateHierarchyOnBackend = async (draggedNodeId: string, newParentId: string) => {
    try {
      await api.post('/api/update-hierarchy/', {
        draggedNodeId,
        newParentId,
      });
    } catch (error) {
      console.error('Error updating hierarchy:', error);
    }
  };

  const closeNodeSidebar = () => {
    setSelectedNode(null);
    setEmployeeDetails(null);
    setSidebarOpen(false);
  };

  const onMoveEnd = useCallback((_event: any, viewport: any) => {
    const { x, y, zoom } = viewport;
    const { x: initialX, y: initialY, zoom: initialZoom } = initialViewport;
    
    const hasMovedSignificantly = 
      Math.abs(x - initialX) > 100 || 
      Math.abs(y - initialY) > 100 || 
      Math.abs(zoom - initialZoom) > 0.1;

    setShowCenterButton(hasMovedSignificantly);
  }, []);

  const handleBackToCenter = useCallback(() => {
    if (reactFlowInstance) {
      reactFlowInstance.setViewport(initialViewport);
      setShowCenterButton(false);
    }
  }, [reactFlowInstance]);

  const updateNodeData = async (updatedData: { name: string; role: string }) => {
    if (!selectedNode || !employeeDetails) return;

    // Update nodes with new employee data
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === selectedNode.id) {
          return {
            ...n,
            data: { 
              ...n.data,
              currentEmployee: {
                ...n.data.currentEmployee,
                name: updatedData.name,
                employee_code: employeeDetails.employee.employee_id
              }
            }
          };
        }
        return n;
      })
    );
    
    // Update selected node
    setSelectedNode({
      ...selectedNode,
      data: {
        ...selectedNode.data,
        currentEmployee: {
          ...selectedNode.data.currentEmployee,
          name: updatedData.name,
          employee_code: employeeDetails.employee.employee_id
        }
      }
    });
    
    // Update employee details
    setEmployeeDetails({
      ...employeeDetails,
      employee: {
        ...employeeDetails.employee,
        name: updatedData.name
      }
    });
  };

  const handleNavigate = (view: 'chart' | 'details' | 'history') => {
    setCurrentView(view);
    setShowHistory(view === 'history');
    setShowKPIs(view === 'details');
  };

  return (
    <div className="flex flex-col h-[calc(100vh-8rem)]">
      <ViewNavigation 
        currentView={currentView}
        onNavigate={handleNavigate}
      />

      <div className="flex-1 relative overflow-hidden">
        {currentView === 'history' ? (
          <div className="absolute inset-0 overflow-auto">
            <EditHistory />
          </div>
        ) : currentView === 'details' ? (
          <div className="absolute inset-0 overflow-auto">
            <KPIs />
          </div>
        ) : (
          <div 
            style={{ 
              width: sidebarOpen ? '50%' : '100%',
              height: '100%',
              transition: 'width 0.3s ease-in-out'
            }}
          >
            <ReactFlowProvider>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeDoubleClick={onNodeDoubleClick}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onInit={setReactFlowInstance}
                onMoveEnd={onMoveEnd}
                onDragOver={onDragOver}
                onDrop={onDrop}
                defaultEdgeOptions={{ type: 'custom' }}
                zoomOnScroll={false}
                panOnScroll={true}
                panOnScrollMode="free"
                fitView={false}
                defaultViewport={{ x: 0, y: 0, zoom: 0.8 }}
                zoomOnPinch={true}
                zoomOnDoubleClick={false}
                preventScrolling={true}
                nodesDraggable={false}
                nodesConnectable={false}
                className="h-full"
                style={{ 
                  background: 'linear-gradient(to right, #FFF5D6, #F2E2A8)',
                  height: '100%'
                }}
              >
                <Controls showZoom={true} showFitView={false} showInteractive={false}>
                  {/* Controls will now only show zoom buttons */}
                </Controls>
                <Background color="#000000" opacity={0.1} />
              </ReactFlow>
              {showCenterButton && (
                <Button
                  onClick={handleBackToCenter}
                  className="absolute bottom-8 right-8 bg-background border border-black text-black hover:bg-black hover:text-white"
                  size="sm"
                >
                  <ArrowUpCircle className="mr-2 h-4 w-4" />
                  Back to Center
                </Button>
              )}
            </ReactFlowProvider>
          </div>
        )}
        {sidebarOpen && selectedNode && employeeDetails && (
          <NodeSidebar
            node={selectedNode}
            employeeDetails={employeeDetails}
            onClose={closeNodeSidebar}
            onUpdate={updateNodeData}
            userId={user?.userId}
          />
        )}
      </div>
    </div>
  );
};

export default AltOrgChart;
