import { Lock } from 'lucide-react';

interface FooterProps {
  variant?: 'default' | 'login';
}

export function Footer({ variant = 'default' }: FooterProps) {
  const isLogin = variant === 'login';
  
  return (
    <footer className={`
      flex items-center justify-center 
      bg-gradient-to-r from-[#FFF5D6] to-[#F2E2A8] 
      border-t border-gray-200
      ${isLogin ? 'py-6' : 'py-3'}
    `}>
      <div className={`
        flex items-center gap-2 
        text-gray-600
        ${isLogin ? 'text-base' : 'text-sm'}
      `}>
        <Lock className={`${isLogin ? 'h-5 w-5' : 'h-4 w-4'} text-primary`} />
        <span>
          End-to-end encrypted. Your data stays private and secure.
        </span>
      </div>
    </footer>
  );
} 