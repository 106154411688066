/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

"use client"

import React, { useState, useEffect, useCallback } from "react"
import { ChevronDown, ChevronUp, User, Briefcase, Building, FileText, Edit3 } from "lucide-react"
import { Button } from "./ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { Badge } from "./ui/badge"
import api from '../api'
import { useAuth } from '../contexts/AuthContext';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select"
import { Input } from "./ui/input"

// Types
type EditType = "ROLE_ASSIGNMENT" | "KRA_EDIT" | "KPI_EDIT"

interface EditHistoryItem {
  id: number;
  timestamp: string;
  edit_type: EditType;
  user: {
    id: number;
    username: string;
    email: string;
  };
  employee_name?: string;
  previous_role?: string;
  new_role?: string;
  item_type?: string;
  item_id?: string;
  previous_description?: string;
  new_description?: string;
}

// Add User type
interface User {
  id: string;
  username: string;
  email: string;
  company: string;
  role: string;
}

interface DiffViewProps {
  previous: any;
  new: any;
}

function DiffView({ previous, new: current }: DiffViewProps) {
  const getAllKeys = () => {
    const allKeys = [...Object.keys(previous || {}), ...Object.keys(current || {})];
    return Array.from(new Set(allKeys)).sort();
  };

  const hasChanged = (key: string) => {
    return JSON.stringify(previous?.[key]) !== JSON.stringify(current?.[key]);
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 gap-4">
        {/* Headers */}
        <div className="text-sm font-semibold text-gray-600 p-2 bg-gray-50 rounded-t">
          Previous Version
        </div>
        <div className="text-sm font-semibold text-gray-600 p-2 bg-gray-50 rounded-t">
          Current Version
        </div>
        
        {/* Content */}
        <div className="col-span-2">
          {getAllKeys().map(key => (
            <div 
              key={key} 
              className={`grid grid-cols-2 gap-4 border-b last:border-b-0 ${
                hasChanged(key) ? 'bg-gray-50' : ''
              }`}
            >
              {/* Previous Value */}
              <div className="p-2">
                <span className="font-medium text-sm mr-2">{key}:</span>
                <span className={`font-mono text-sm ${
                  hasChanged(key) ? 'text-red-600' : ''
                }`}>
                  {JSON.stringify(previous?.[key]) || '(undefined)'}
                </span>
              </div>

              {/* Current Value */}
              <div className="p-2">
                <span className="font-medium text-sm mr-2">{key}:</span>
                <span className={`font-mono text-sm ${
                  hasChanged(key) ? 'text-green-600' : ''
                }`}>
                  {JSON.stringify(current?.[key]) || '(undefined)'}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const typeIcons: Record<EditType, React.ReactNode> = {
  "ROLE_ASSIGNMENT": <Briefcase className="w-4 h-4" />,
  "KRA_EDIT": <FileText className="w-4 h-4" />,
  "KPI_EDIT": <Building className="w-4 h-4" />
}

const typeLabels: Record<EditType, string> = {
  "ROLE_ASSIGNMENT": "Role Assignment Change",
  "KRA_EDIT": "KRA Edit",
  "KPI_EDIT": "KPI Edit"
}

function getDateRange(range: string): [string, string] {
  const end = new Date();
  let start = new Date();
  switch (range) {
    case 'last7':
      start.setDate(end.getDate() - 7);
      break;
    case 'last30':
      start.setDate(end.getDate() - 30);
      break;
    case 'last90':
      start.setDate(end.getDate() - 90);
      break;
    default:
      return ['', ''];
  }
  return [start.toISOString().split('T')[0], end.toISOString().split('T')[0]];
}

const EditHistory: React.FC = () => {
  const { user } = useAuth();
  const [expandedItems, setExpandedItems] = useState<number[]>([])
  const [typeFilter, setTypeFilter] = useState<EditType | "ALL">("ALL")
  const [dateRange, setDateRange] = useState<string>('all')
  const [editorFilter, setEditorFilter] = useState("all-editors")
  const [history, setHistory] = useState<EditHistoryItem[]>([])
  const [editors, setEditors] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState("")

  const fetchHistory = useCallback(async () => {
    try {
      if (!user?.userId) {
        console.error('No user ID available');
        return;
      }

      const response = await api.get('/api/edit-history/', {
        params: {
          userId: user.userId
        }
      });
      
      setHistory(response.data);
      
      const uniqueEditors = Array.from(
        new Set(response.data.map((item: EditHistoryItem) => item.user.username))
      ) as string[];
      
      setEditors(uniqueEditors);
    } catch (error) {
      console.error('Error fetching edit history:', error);
    }
  }, [user?.userId]);

  useEffect(() => {
    if (user?.userId) {
      fetchHistory();
    }
  }, [user?.userId, fetchHistory]);

  const toggleExpand = (id: number) => {
    setExpandedItems(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    )
  }

  const renderChange = (item: EditHistoryItem) => {
    if (item.edit_type === 'ROLE_ASSIGNMENT') {
      return (
        <div className="grid grid-cols-2 gap-4 mt-2">
          <div className="bg-red-50 p-3 rounded-lg">
            <h4 className="font-medium text-sm mb-1">Previous Role</h4>
            <p className="text-red-600">{item.previous_role || 'None'}</p>
          </div>
          <div className="bg-green-50 p-3 rounded-lg">
            <h4 className="font-medium text-sm mb-1">New Role</h4>
            <p className="text-green-600">{item.new_role}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-2 gap-4 mt-2">
        <div className="bg-red-50 p-3 rounded-lg">
          <h4 className="font-medium text-sm mb-1">Previous Description</h4>
          <p className="text-red-600">{item.previous_description || 'None'}</p>
        </div>
        <div className="bg-green-50 p-3 rounded-lg">
          <h4 className="font-medium text-sm mb-1">New Description</h4>
          <p className="text-green-600">{item.new_description}</p>
        </div>
      </div>
    );
  };

  const getChangeDescription = (item: EditHistoryItem): string => {
    switch (item.edit_type) {
      case "ROLE_ASSIGNMENT":
        return `Role change for ${item.employee_name}`;
      case "KRA_EDIT":
        return `KRA update`;
      case "KPI_EDIT":
        return `KPI update`;
      default:
        return "Unknown change";
    }
  };

  const filteredHistory = history.filter(item => {
    const typeMatch = typeFilter === "ALL" || item.edit_type === typeFilter
    const [startDate, endDate] = getDateRange(dateRange)
    const dateMatch = dateRange === 'all' || 
      (item.timestamp >= startDate && item.timestamp <= endDate)
    const editorMatch = editorFilter === 'all-editors' || 
      item.user.username.toLowerCase() === editorFilter.toLowerCase()
    return typeMatch && dateMatch && editorMatch
  })

  // Filter editors based on search term
  const filteredEditors = editors.filter(editor => 
    editor.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="flex flex-col h-[calc(100vh-8rem)]">
      <div className="flex-1 overflow-auto bg-background">
        <div className="container mx-auto p-4 max-w-4xl">
          <h1 className="text-2xl font-bold mb-4">Change History</h1>
          
          {/* Add Filters Section */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <div>
              <label className="text-sm font-medium mb-1 block">Type</label>
              <Select
                value={typeFilter}
                onValueChange={(value) => setTypeFilter(value as EditType | "ALL")}
              >
                <SelectTrigger className="bg-background">
                  <SelectValue placeholder="Filter by type" />
                </SelectTrigger>
                <SelectContent className="bg-background">
                  <SelectItem value="ALL">All Types</SelectItem>
                  <SelectItem value="ROLE_ASSIGNMENT">Role Assignment Change</SelectItem>
                  <SelectItem value="KRA_EDIT">KRA Edit</SelectItem>
                  <SelectItem value="KPI_EDIT">KPI Edit</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div>
              <label className="text-sm font-medium mb-1 block">Date Range</label>
              <Select
                value={dateRange}
                onValueChange={setDateRange}
              >
                <SelectTrigger className="bg-background">
                  <SelectValue placeholder="Select date range" />
                </SelectTrigger>
                <SelectContent className="bg-background">
                  <SelectItem value="all">All Time</SelectItem>
                  <SelectItem value="last7">Last 7 Days</SelectItem>
                  <SelectItem value="last30">Last 30 Days</SelectItem>
                  <SelectItem value="last90">Last 90 Days</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div>
              <label className="text-sm font-medium mb-1 block">Editor</label>
              <Select
                value={editorFilter}
                onValueChange={setEditorFilter}
              >
                <SelectTrigger className="bg-background">
                  <SelectValue placeholder="Filter by editor" />
                </SelectTrigger>
                <SelectContent className="bg-background">
                  <SelectItem value="all-editors">All Editors</SelectItem>
                  {editors.map((editor) => (
                    <SelectItem key={editor} value={editor}>
                      {editor}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div>
              <label className="text-sm font-medium mb-1 block">Search Editor</label>
              <Input
                type="text"
                placeholder="Search editors..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-background"
              />
            </div>
          </div>

          {/* Existing history list */}
          <div className="space-y-4">
            {filteredHistory.map((item) => (
              <Card key={item.id} className="border-l-4" style={{ borderLeftColor: getTypeColor(item.edit_type) }}>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    <div className="flex items-center space-x-2">
                      {typeIcons[item.edit_type]}
                      <span>{new Date(item.timestamp).toLocaleString()}</span>
                      <Badge variant="secondary">{getChangeDescription(item)}</Badge>
                    </div>
                  </CardTitle>
                  <div className="flex items-center text-sm text-muted-foreground">
                    <Edit3 className="w-4 h-4 mr-1" />
                    <span>{item.user.username}</span>
                  </div>
                </CardHeader>
                <CardContent>
                  {renderChange(item)}
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function getTypeColor(type: EditType): string {
  switch (type) {
    case "ROLE_ASSIGNMENT":
      return "#3b82f6"
    case "KRA_EDIT":
      return "#10b981"
    case "KPI_EDIT":
      return "#f59e0b"
    default:
      return "#6b7280"
  }
}

export default EditHistory;
