import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link, useNavigate, useLocation } from 'react-router-dom';
import { LogOut } from 'lucide-react';
import Approval from './components/Approval';
import OrgChart from './components/OrgChart';
import Login from './components/Login';
import Goals from './components/Goals';
import Decisions from './components/decisions/Decisions';
import { KPIProvider } from './contexts/KPIContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import MoonshotsCollect from './components/moonshots/MoonshotsCollect';
import MoonshotsDisplay from './components/moonshots/MoonshotsDisplay';
import ProtectedRoute from './components/ProtectedRoute';
import Unauthorized from './components/Unauthorized';
import EmployeeDashboard from './components/Employee';
import EditHistory from './components/EditHistory';
import EmployeeOrgChart from './components/EmployeeOrgChart';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/globals.css';
import { Footer } from './components/ui/footer';
import FrenemLandingPage from './components/Welcome';

const Header: React.FC = () => {
  const { isAuthenticated, logout, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const loadLogo = async () => {
      if (user && user.company) {
        try {
          const logoModule = await import(`./assets/${user.company} Logo.png`);
          setLogo(logoModule.default);
        } catch (error) {
          console.error(`Failed to load logo for ${user.company}:`, error);
        }
      }
    };

    loadLogo();
  }, [user]);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  if (!isAuthenticated) return null;

  const isActive = (path: string) => {
    if (path === '/moonshots') {
      return location.pathname === '/moonshots/display';
    }
    return location.pathname.startsWith(path);
  };

  const navLinkClass = (path: string) =>
    `text-base font-semibold px-6 py-3 rounded-lg transition-all duration-200 ${
      isActive(path)
        ? 'text-white bg-black shadow-md transform scale-105'
        : 'text-black hover:bg-black hover:text-white hover:scale-105'
    }`;

  const renderNavLinks = () => {
    const links = [];

    if (user?.role === 'admin' || user?.role === 'promoter') {
      links.push(
        <Link 
          key="orgchart" 
          to="/orgchart" 
          className={navLinkClass('/orgchart')}
        >
          Your Organisation
        </Link>
      );
      links.push(
        <Link 
          key="decisions" 
          to="/decisions" 
          className={navLinkClass('/decisions')}
        >
          Decisions
        </Link>
      );
      links.push(
        <Link 
          key="innovations" 
          to="/moonshots/display" 
          className={navLinkClass('/moonshots')}
        >
          Innovations
        </Link>
      );
      links.push(
        <Link 
          key="goals" 
          to="/goals" 
          className={navLinkClass('/goals')}
        >
          Goals
        </Link>
      );
    }

    if (user?.role === 'employee') {
      links.push(
        <Link 
          key="orgchart" 
          to="/employee-orgchart" 
          className={navLinkClass('/employee-orgchart')}
        >
          Organisation
        </Link>
      );
      links.push(
        <Link 
          key="dashboard" 
          to="/employee-dashboard" 
          className={navLinkClass('/employee-dashboard')}
        >
          Dashboard
        </Link>
      );
      links.push(
        <Link 
          key="goals" 
          to="/goals" 
          className={navLinkClass('/goals')}
        >
          Goals
        </Link>
      );
    }

    if (user?.role === 'employee' || user?.role === 'admin') {
      links.push(
        <Link 
          key="moonshots-collect" 
          to="/moonshots/collect" 
          className={navLinkClass('/moonshots/collect')}
        >
          Innovations
        </Link>
      );
    }

    if (user?.role === 'admin') {
      links.push(
        <Link 
          key="approval" 
          to="/approval" 
          className={navLinkClass('/approval')}
        >
          Approvals
        </Link>
      );
    }

    return links;
  };

  return (
    <header className="bg-background border-b border-black text-black h-16 app-header shadow-md">
      <div className="px-6 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          {logo && <img src={logo} alt={`${user?.company} Logo`} className="h-8 w-auto cursor-pointer" />}
        </div>
        <nav className="flex-grow flex justify-center items-center space-x-6">
          {renderNavLinks()}
        </nav>
        <button
          onClick={handleLogout}
          className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-lg text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200 shadow-sm"
        >
          <LogOut size={16} className="mr-2" />
          Logout
        </button>
      </div>
    </header>
  );
};

const AppRoutes: React.FC = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Routes>
      <Route path="/" element={
        isAuthenticated 
          ? (user?.role === 'employee' 
              ? <Navigate to="/employee-orgchart" replace /> 
              : <Navigate to="/orgchart" replace />)
          : <FrenemLandingPage />
      } />
      <Route path="/login" element={<Login />} />
      <Route path="/approval" element={<ProtectedRoute element={<Approval />} allowedRoles={['admin']} />} />
      <Route path="/decisions" element={<ProtectedRoute element={<Decisions />} allowedRoles={['admin', 'promoter']} />} />
      <Route path="/moonshots/collect" element={<ProtectedRoute element={<MoonshotsCollect />} allowedRoles={['employee', 'admin']} />} />
      <Route path="/moonshots/display" element={<ProtectedRoute element={<MoonshotsDisplay />} allowedRoles={['admin', 'promoter']} />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/employee-dashboard" element={<ProtectedRoute element={<EmployeeDashboard />} allowedRoles={['employee']} />} />
      <Route path="/employee-orgchart" element={<ProtectedRoute element={<EmployeeOrgChart />} allowedRoles={['employee']} />} />
      <Route path="/orgchart" element={<ProtectedRoute element={<OrgChart />} allowedRoles={['admin', 'promoter']} />} />
      <Route path="/goals" element={<ProtectedRoute element={<Goals />} allowedRoles={['admin', 'promoter', 'employee']} />} />
      <Route 
        path="/edit-history" 
        element={
          <ProtectedRoute 
            element={<EditHistory />} 
            allowedRoles={['admin', 'promoter']} 
          />
        } 
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();
  const isLargeFooter = location.pathname === '/login' || location.pathname === '/';

  return (
    <AuthProvider>
      <KPIProvider>
        <div className="min-h-screen flex flex-col">
          <Header />
          <main className="flex-1 flex flex-col overflow-hidden">
            <AppRoutes />
          </main>
          <Footer variant={isLargeFooter ? "login" : "default"} />
        </div>
      </KPIProvider>
    </AuthProvider>
  );
};

export default App;
