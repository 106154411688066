"use client"

import { useState, useEffect } from "react"
import api from '../../api'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Input } from "../ui/input"
import { Textarea } from "../ui/textarea"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select"
import { Button } from "../ui/button"
import { Toast, ToastProvider, ToastViewport, ToastTitle, ToastDescription } from "../ui/use-toast" // Import Toast components
import { useAuth } from '../../contexts/AuthContext';

export default function EmployeeEngagementTool() {
  const [moonshotIdea, setMoonshotIdea] = useState("")
  const [moonshotPlan, setMoonshotPlan] = useState("")
  const [moonshotResources, setMoonshotResources] = useState("")
  const [innovationIdea, setInnovationIdea] = useState("")
  const [innovationType, setInnovationType] = useState("")
  const [whistleblowerReport, setWhistleblowerReport] = useState("")
  const [weeklyTheme, setWeeklyTheme] = useState("")
  const [toast, setToast] = useState<{ title: string; description: string; variant?: "default" | "destructive" } | null>(null)
  const { user } = useAuth();

  useEffect(() => {
    // Simulating fetching the weekly theme
    const themes = ["Operational Efficiency"]
    const randomTheme = themes[Math.floor(Math.random() * themes.length)]
    setWeeklyTheme(randomTheme)
  }, [])

  const showToast = (title: string, description: string, variant: "default" | "destructive" = "default") => {
    setToast({ title, description, variant })
    setTimeout(() => setToast(null), 5000) // Hide toast after 5 seconds
  }

  const handleSubmit = (type: string) => {
    let payload;
    
    switch (type) {
      case 'moonshot':
        payload = {
          report_type: type,
          idea: moonshotIdea,
          theme: weeklyTheme,
          plan: moonshotPlan,
          resources: moonshotResources,
        };
        break;
      case 'innovation':
        payload = {
          report_type: type,
          idea: innovationIdea,
          type: innovationType,
        };
        break;
      case 'whistleblower':
        payload = {
          report_type: type,
          report: whistleblowerReport,
        };
        break;
      default:
        console.error('Invalid report type');
        return;
    }

    const companyName = user?.company || 'Kaynes'; // Use 'Kaynes' as fallback
    api.post(`/api/moonshots/${companyName}/collect/`, payload)
      .then(response => {
        console.log(`Submitted ${type}:`, response.data);
        // Reset form fields after submission
        if (type === 'moonshot') {
          setMoonshotIdea("");
          setMoonshotPlan("");
          setMoonshotResources("");
        } else if (type === 'innovation') {
          setInnovationIdea("");
          setInnovationType("");
        } else if (type === 'whistleblower') {
          setWhistleblowerReport("");
        }
        
        // Show success notification
        showToast("Success", "Your submission has been received.")
      })
      .catch(error => {
        console.error('Error submitting report:', error);
        // Show error notification
        showToast("Error", "There was a problem submitting your report. Please try again.", "destructive")
      });
  }

  return (
    <ToastProvider>
      <div className="p-4 bg-gradient-to-r from-[#FFF5D6] to-[#F2E2A8]">
        <div className="mb-6">
          <h2 className="text-2xl font-bold mb-2">Moonshots Submission</h2>
          <p className="text-muted-foreground">
            Submit moonshot ideas, feedback, and anonymous reports
          </p>
        </div>
        <Tabs defaultValue="moonshots" className="w-full">
          <TabsList className="grid w-full bg-white opacity-70 grid-cols-3">
            <TabsTrigger value="moonshots">Moonshots</TabsTrigger>
            <TabsTrigger value="innovation">Innovations</TabsTrigger>
            <TabsTrigger value="whistleblower">Whistleblower</TabsTrigger>
          </TabsList>
          <TabsContent value="moonshots">
            <Card>
              <CardHeader>
                <CardTitle>Moonshots</CardTitle>
                <CardDescription>Help build the next big thing. Share your idea, project plan, and resource requirements.</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="mb-4 p-2 bg-blue-100 rounded">
                  <p className="font-bold">Weekly Theme</p>
                  <p>This week's moonshot theme: {weeklyTheme}</p>
                </div>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="moonshot-idea" className="block mb-2">Idea (based on weekly theme)</label>
                    <Input 
                      id="moonshot-idea" 
                      placeholder="Your big idea" 
                      value={moonshotIdea} 
                      onChange={(e) => setMoonshotIdea(e.target.value)} 
                    />
                  </div>
                  <div>
                    <label htmlFor="moonshot-plan" className="block mb-2">Project Plan</label>
                    <Textarea 
                      id="moonshot-plan" 
                      placeholder="Outline your project plan" 
                      value={moonshotPlan} 
                      onChange={(e) => setMoonshotPlan(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="moonshot-resources" className="block mb-2">Resource Requirements</label>
                    <Textarea 
                      id="moonshot-resources" 
                      placeholder="List required resources" 
                      value={moonshotResources} 
                      onChange={(e) => setMoonshotResources(e.target.value)}
                    />
                  </div>
                  <Button onClick={() => handleSubmit('moonshot')} className="w-full">
                    Submit Moonshot
                  </Button>
                </div>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="innovation">
            <Card>
              <CardHeader>
                <CardTitle>Innovations</CardTitle>
                <CardDescription>Share your ideas on how to run the company better. Focus on efficiency, profitability, or collaboration.</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="innovation-type" className="block mb-2">Submission Type</label>
                    <Select value={innovationType} onValueChange={setInnovationType}>
                      <SelectTrigger id="innovation-type">
                        <SelectValue placeholder="Select Type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="innovation">Innovation</SelectItem>
                        <SelectItem value="feedback">Feedback</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label htmlFor="innovation-idea" className="block mb-2">Your Idea</label>
                    <Textarea 
                      id="innovation-idea" 
                      placeholder="Describe your innovation idea" 
                      value={innovationIdea} 
                      onChange={(e) => setInnovationIdea(e.target.value)}
                    />
                  </div>
                  <Button onClick={() => handleSubmit('innovation')} className="w-full">
                    Submit Innovation Idea
                  </Button>
                </div>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="whistleblower">
            <Card>
              <CardHeader>
                <CardTitle>Whistleblower</CardTitle>
                <CardDescription>Report unethical practices anonymously.</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="whistleblower-report" className="block mb-2">Anonymous Report</label>
                    <Textarea 
                      id="whistleblower-report" 
                      placeholder="Describe the unethical practice you've observed" 
                      value={whistleblowerReport} 
                      onChange={(e) => setWhistleblowerReport(e.target.value)}
                      rows={6}
                    />
                  </div>
                  <Button onClick={() => handleSubmit('whistleblower')} className="w-full">
                    Submit Anonymous Report
                  </Button>
                </div>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
      {toast && (
        <Toast variant={toast.variant}>
          <ToastTitle>{toast.title}</ToastTitle>
          <ToastDescription>{toast.description}</ToastDescription>
        </Toast>
      )}
      <ToastViewport />
    </ToastProvider>
  )
}
