import React from 'react';
import { Network, ListChecks, History } from 'lucide-react';

type ViewNavigationProps = {
  currentView: 'chart' | 'details' | 'history';
  onNavigate: (view: 'chart' | 'details' | 'history') => void;
};

export default function ViewNavigation({ currentView, onNavigate }: ViewNavigationProps) {
  const navLinkClass = (view: 'chart' | 'details' | 'history') =>
    `text-base font-semibold px-6 py-3 rounded-lg transition-all duration-200 inline-flex items-center border ${
      currentView === view
        ? 'text-white bg-black shadow-md transform scale-105'
        : 'text-black hover:bg-black hover:text-white hover:scale-105'
    }`;

  return (
    <nav className="flex items-center justify-between py-4 px-6 bg-background border-b border-black">
      <div className="flex items-center space-x-4">
        <button
          onClick={() => onNavigate('chart')}
          className={navLinkClass('chart')}
        >
          <Network className="mr-2 h-4 w-4" />
          Org Chart
        </button>
        <button
          onClick={() => onNavigate('details')}
          className={navLinkClass('details')}
        >
          <ListChecks className="mr-2 h-4 w-4" />
          Details
        </button>
      </div>
      <button
        onClick={() => onNavigate('history')}
        className={navLinkClass('history')}
      >
        <History className="mr-2 h-4 w-4" />
        History
      </button>
    </nav>
  );
} 