import React, { useState, createContext, useEffect } from 'react'
import { ChevronRight, Check, Trash2, Edit2, Plus } from 'lucide-react'
import api from '../api';

interface KPI {
  description: string;
  status: string;
  needsReview: boolean;
}

interface KRA {
  description: string;
  responsibilities: string[];
  status: string;
  needsReview: boolean;
}

interface Employee {
  id: string;
  name: string;
  title: string;
  kpis: KPI[];
  kras: KRA[];
}

interface Department {
  name: string;
  employees: Employee[];
}

interface KPIContextType {
  departments: Department[];
  approveItem: () => void;
  publishApprovedItems: () => void;
}

// Add these new interfaces
interface EditingEmployee {
  id: string;
  name: string;
  title: string;
}

interface EditingKRA {
  employeeId: string;
  index: number;
  description: string;
  responsibilities: string[];
}

interface EditingKPI {
  employeeId: string;
  index: number;
  description: string;
}

const KPIContext = createContext<KPIContextType>({
  departments: [],
  approveItem: () => {},
  publishApprovedItems: () => {},
})

const RedDot = () => (
  <span className="inline-block w-2 h-2 bg-red-500 rounded-full ml-2"></span>
)

export default function Component() {
  const [departments, setDepartments] = useState<Department[]>([])
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null)
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set())
  const [editingItem, setEditingItem] = useState<{ employeeId: string, type: 'kpi' | 'kra', index: number, description: string } | null>(null)
  const [newItemType, setNewItemType] = useState<{ employeeId: string, type: 'kpi' | 'kra' } | null>(null)
  const [newItemDescription, setNewItemDescription] = useState('')

  // Add these new state variables
  const [editingEmployee, setEditingEmployee] = useState<EditingEmployee | null>(null);
  const [editingKRA, setEditingKRA] = useState<EditingKRA | null>(null);
  const [editingKPI, setEditingKPI] = useState<EditingKPI | null>(null);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const response = await api.get('/api/admin-approve/Kaynes')
      if (!response.status) {
        throw new Error('Network response was not ok')
      }
      const data = await response.data
      setDepartments(data.departments.map((dept: any) => ({
        ...dept,
        employees: dept.employees.map((emp: any) => ({
          ...emp,
          kpis: emp.kpis.map((kpi: any) => ({ 
            description: kpi.description, // Changed 'description' to 'Description'
            status: kpi.status,
            needsReview: kpi.needsReview 
          })),
          kras: emp.kras.map((kra: any) => ({ 
            description: kra.description, // Changed 'description' to 'Description'
            responsibilities: kra.responsibilities,
            status: kra.status,
            needsReview: kra.needsReview 
          }))
        }))
      })))
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const employees = departments.flatMap(dept => dept.employees)

  const totalItems = employees.reduce((acc, employee) => 
    acc + employee.kpis.length + employee.kras.length, 0)

  const approvedItems = employees.reduce((acc, employee) => 
    acc + employee.kpis.filter(kpi => kpi.status === 'approved').length + employee.kras.filter(kra => kra.status === 'approved').length, 0)

  const toggleEmployee = (employeeId: string) => {
    setSelectedEmployee(prev => prev === employeeId ? null : employeeId)
  }

  const handleSelectItem = (employeeId: string, itemType: 'kpi' | 'kra', index: number) => {
    const itemKey = `${employeeId}-${itemType}-${index}`
    setSelectedItems(prev => {
      const newSet = new Set(prev)
      if (newSet.has(itemKey)) {
        newSet.delete(itemKey)
      } else {
        newSet.add(itemKey)
      }
      return newSet
    })
  }

  const handleSelectAll = (employeeId: string, itemType: 'kpi' | 'kra') => {
    const employee = employees.find(emp => emp.id === employeeId)
    if (employee) {
      const items = itemType === 'kpi' ? employee.kpis : employee.kras
      const itemKeys = items.map((_, index) => `${employeeId}-${itemType}-${index}`)
      setSelectedItems(prev => {
        const newSet = new Set(prev)
        if (itemKeys.every(key => newSet.has(key))) {
          itemKeys.forEach(key => newSet.delete(key))
        } else {
          itemKeys.forEach(key => newSet.add(key))
        }
        return newSet
      })
    }
  }

  const handleApproveSelected = async () => {
    const employeeUpdates = new Map<string, { kpis: KPI[], kras: KPI[] }>();

    // Collect updates for each employee
    selectedItems.forEach(itemKey => {
      const [employeeId, itemType, indexStr] = itemKey.split('-');
      const index = parseInt(indexStr, 10);
      
      if (!employeeUpdates.has(employeeId)) {
        const employee = employees.find(emp => emp.id === employeeId);
        if (employee) {
          employeeUpdates.set(employeeId, { kpis: [...employee.kpis], kras: [...employee.kras] });
        }
      }

      const employeeUpdate = employeeUpdates.get(employeeId);
      if (employeeUpdate) {
        const items = itemType === 'kpi' ? employeeUpdate.kpis : employeeUpdate.kras;
        if (items[index]) {
          items[index] = { ...items[index], status: 'approved' };
        }
      }
    });

    // Update backend for each employee
    const updatePromises = Array.from(employeeUpdates.entries()).map(([employeeId, update]) => {
      return api.put('/api/update-temporary-employee/', {
        employee_id: employeeId,
        item_type: 'kpis',
        items: update.kpis
      }).then(() => {
        return api.put('/api/update-temporary-employee/', {
          employee_id: employeeId,
          item_type: 'kras',
          items: update.kras
        });
      });
    });

    try {
      await Promise.all(updatePromises);
      console.log('All selected items updated successfully');
    } catch (error) {
      console.error('Error updating selected items:', error);
      // Optionally, show an error message to the user
    }

    // Update local state
    setDepartments(prevDepartments => {
      return prevDepartments.map(dept => ({
        ...dept,
        employees: dept.employees.map(emp => {
          const update = employeeUpdates.get(emp.id);
          if (update) {
            return {
              ...emp,
              kpis: update.kpis,
              kras: update.kras,
            };
          }
          return emp;
        }),
      }));
    });

    // Clear selected items
    setSelectedItems(new Set());
  };

  const handlePublish = async () => {
    try {
      const response = await api.post('/api/publish-approved-items/', {
        departments
      })
      
      if (!response.status) {
        const errorData = await response.data
        throw new Error(errorData.error || 'Failed to publish approved items')
      }
      
      const result = await response.data
      console.log('Publish successful:', result)
      
      // {{ edit_1 }} Provide user feedback upon successful publication
      alert(result.status)

      // Optionally, refresh data to reflect published changes
      fetchData()
    } catch (error) {
      console.error('Error publishing items:', error)
      // Optionally, display an error message to the user
    }
  }

  const updateBackend = async (employeeId: string, itemType: 'kpis' | 'kras', items: KPI[]) => {
    try {
      const response = await api.put('/api/update-temporary-employee/', {
        employee_id: employeeId,
        item_type: itemType,
        items: items
      });
      console.log(response.data.status);
    } catch (error) {
      console.error('Error updating backend:', error);
    }
  };

  const handleDelete = (employeeId: string, type: 'kpi' | 'kra', index: number) => {
    setDepartments(prevDepartments => {
      const newDepartments = prevDepartments.map(dept => ({
        ...dept,
        employees: dept.employees.map(emp => {
          if (emp.id === employeeId) {
            const updatedItems = emp[type === 'kpi' ? 'kpis' : 'kras'].filter((_, i) => i !== index);
            updateBackend(emp.id, type === 'kpi' ? 'kpis' : 'kras', updatedItems);
            return {
              ...emp,
              [type === 'kpi' ? 'kpis' : 'kras']: updatedItems
            };
          }
          return emp;
        }),
      }));
      return newDepartments;
    });
  }

  const handleSaveEdit = () => {
    if (editingItem) {
      setDepartments(prevDepartments => {
        const newDepartments = prevDepartments.map(dept => ({
          ...dept,
          employees: dept.employees.map(emp => {
            if (emp.id === editingItem.employeeId) {
              const itemType = editingItem.type === 'kpi' ? 'kpis' : 'kras';
              const updatedItems = emp[itemType].map((item: KPI, i: number) => 
                i === editingItem.index ? { ...item, description: editingItem.description } : item
              );
              updateBackend(emp.id, itemType, updatedItems);
              return {
                ...emp,
                [itemType]: updatedItems
              };
            }
            return emp;
          }),
        }));
        return newDepartments;
      });
      setEditingItem(null);
    }
  };

  const handleAddNew = (employeeId: string, type: 'kpi' | 'kra') => {
    setNewItemType({ employeeId, type })
    setNewItemDescription('')
  }

  const handleSaveNewItem = () => {
    if (newItemType && newItemDescription) {
      setDepartments(prevDepartments => {
        const newDepartments = prevDepartments.map(dept => ({
          ...dept,
          employees: dept.employees.map(emp => {
            if (emp.id === newItemType.employeeId) {
              const newItem: KPI = {
                description: newItemDescription,
                status: 'pending',
                needsReview: false
              };
              const itemType = newItemType.type === 'kpi' ? 'kpis' : 'kras';
              const updatedItems = [...emp[itemType], newItem];
              updateBackend(emp.id, itemType, updatedItems);
              return {
                ...emp,
                [itemType]: updatedItems
              };
            }
            return emp;
          }),
        }));
        return newDepartments;
      });
      setNewItemType(null);
      setNewItemDescription('');
    }
  };

  const handleToggleApproval = (employeeId: string, type: 'kpi' | 'kra', index: number) => {
    setDepartments(prevDepartments => {
      const newDepartments = prevDepartments.map(dept => ({
        ...dept,
        employees: dept.employees.map(emp => {
          if (emp.id === employeeId) {
            const updatedItems = emp[type === 'kpi' ? 'kpis' : 'kras'].map((item, i) => 
              i === index ? { ...item, status: item.status === 'approved' ? 'pending' : 'approved' } : item
            );
            updateBackend(emp.id, type === 'kpi' ? 'kpis' : 'kras', updatedItems);
            return {
              ...emp,
              [type === 'kpi' ? 'kpis' : 'kras']: updatedItems
            };
          }
          return emp;
        }),
      }));
      return newDepartments;
    });
  }

  // Add this new function to handle approving all items
  const handleApproveAll = () => {
    setDepartments(prevDepartments => {
      return prevDepartments.map(dept => ({
        ...dept,
        employees: dept.employees.map(emp => ({
          ...emp,
          kpis: emp.kpis.map(kpi => ({ ...kpi, status: 'approved' })),
          kras: emp.kras.map(kra => ({ ...kra, status: 'approved' })),
        })),
      }))
    })
    // Clear selected items as everything is now approved
    setSelectedItems(new Set())
  }

  // Add these new functions
  const handleEditEmployee = (e: React.MouseEvent, employee: Employee) => {
    e.stopPropagation(); // Prevent the click from toggling the employee card
    setEditingEmployee({ id: employee.id, name: employee.name, title: employee.title });
  };

  const handleSaveEmployee = async (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the click from toggling the employee card
    if (editingEmployee) {
      try {
        // Use the existing update-temporary-employee endpoint
        await api.put('/api/update-temporary-employee/', {
          employee_id: editingEmployee.id,
          item_type: 'employee_details',
          items: {
            name: editingEmployee.name,
            title: editingEmployee.title,
          }
        });

        setDepartments(prevDepartments => 
          prevDepartments.map(dept => ({
            ...dept,
            employees: dept.employees.map(emp => 
              emp.id === editingEmployee.id ? { ...emp, name: editingEmployee.name, title: editingEmployee.title } : emp
            ),
          }))
        );

        setEditingEmployee(null);
      } catch (error) {
        console.error('Error updating employee:', error);
      }
    }
  };

  const handleEditKRA = (employeeId: string, kra: KRA, index: number) => {
    setEditingKRA({ employeeId, index, description: kra.description, responsibilities: [...kra.responsibilities] });
  };

  const handleSaveKRA = async () => {
    if (editingKRA) {
      try {
        const employee = employees.find(emp => emp.id === editingKRA.employeeId);
        if (employee) {
          const updatedKRAs = [...employee.kras];
          updatedKRAs[editingKRA.index] = {
            ...updatedKRAs[editingKRA.index],
            description: editingKRA.description,
            responsibilities: editingKRA.responsibilities,
          };

          await updateBackend(editingKRA.employeeId, 'kras', updatedKRAs);

          setDepartments(prevDepartments => 
            prevDepartments.map(dept => ({
              ...dept,
              employees: dept.employees.map(emp => 
                emp.id === editingKRA.employeeId ? { ...emp, kras: updatedKRAs } : emp
              ),
            }))
          );

          setEditingKRA(null);
        }
      } catch (error) {
        console.error('Error updating KRA:', error);
      }
    }
  };

  const handleEditKPI = (employeeId: string, kpi: KPI, index: number) => {
    setEditingKPI({ employeeId, index, description: kpi.description });
  };

  const handleSaveKPI = async () => {
    if (editingKPI) {
      try {
        const employee = employees.find(emp => emp.id === editingKPI.employeeId);
        if (employee) {
          const updatedKPIs = [...employee.kpis];
          updatedKPIs[editingKPI.index] = {
            ...updatedKPIs[editingKPI.index],
            description: editingKPI.description,
          };

          await updateBackend(editingKPI.employeeId, 'kpis', updatedKPIs);

          setDepartments(prevDepartments => 
            prevDepartments.map(dept => ({
              ...dept,
              employees: dept.employees.map(emp => 
                emp.id === editingKPI.employeeId ? { ...emp, kpis: updatedKPIs } : emp
              ),
            }))
          );

          setEditingKPI(null);
        }
      } catch (error) {
        console.error('Error updating KPI:', error);
      }
    }
  };

  const handleDeleteResponsibility = (respIndex: number) => {
    if (editingKRA) {
      const newResponsibilities = editingKRA.responsibilities.filter((_, index) => index !== respIndex);
      setEditingKRA({ ...editingKRA, responsibilities: newResponsibilities });
    }
  };

  return (
    <KPIContext.Provider value={{ departments, approveItem: () => {}, publishApprovedItems: handlePublish }}>
      <div className="flex h-screen overflow-hidden bg-cream">
        <div className="flex-1 flex flex-col">
          <header className="bg-white border-b border-gray-200 sticky top-0 z-20">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
              <h1 className="text-2xl font-semibold text-gray-900">KPI/KRA Approval Dashboard</h1>
              <div className="flex items-center space-x-4">
                <div className="text-sm text-gray-600">
                  Approved: {approvedItems} / {totalItems}
                </div>
                {/* Add the Approve All button here */}
                <button
                  onClick={handleApproveAll}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Approve All
                </button>
                <button
                  onClick={handleApproveSelected}
                  disabled={selectedItems.size === 0}
                  className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                >
                  Approve Selected
                </button>
                <button
                  onClick={handlePublish}
                  disabled={approvedItems !== totalItems}
                  className="px-4 py-2 border border-gray-300 rounded disabled:opacity-50"
                >
                  Publish
                </button>
              </div>
            </div>
          </header>

          <main className="flex-grow overflow-y-auto">
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="divide-y divide-gray-200">
                {employees.map(employee => {
                  const allKpisApproved = employee.kpis.every(kpi => kpi.status === 'approved')
                  const allKrasApproved = employee.kras.every(kra => kra.status === 'approved')
                  const allApproved = allKpisApproved && allKrasApproved

                  return (
                    <div key={employee.id} className="py-4 hover:bg-gray-50 transition-colors">
                      <button
                        onClick={() => toggleEmployee(employee.id)}
                        className="flex items-center justify-between w-full text-left"
                      >
                        <span className="text-sm flex items-center">
                          {editingEmployee && editingEmployee.id === employee.id ? (
                            <>
                              <input
                                value={editingEmployee.name}
                                onChange={(e) => setEditingEmployee({ ...editingEmployee, name: e.target.value })}
                                className="mr-2 px-1 border rounded"
                                onClick={(e) => e.stopPropagation()} // Prevent the click from toggling the employee card
                              />
                              <input
                                value={editingEmployee.title}
                                onChange={(e) => setEditingEmployee({ ...editingEmployee, title: e.target.value })}
                                className="mr-2 px-1 border rounded"
                                onClick={(e) => e.stopPropagation()} // Prevent the click from toggling the employee card
                              />
                              <button onClick={handleSaveEmployee} className="text-blue-600 hover:text-blue-800">Save</button>
                            </>
                          ) : (
                            <>
                              <span className="font-medium text-gray-900">{employee.name}</span>
                              <span className="text-gray-500 ml-2">{employee.title}</span>
                              <button onClick={(e) => handleEditEmployee(e, employee)} className="ml-2 text-blue-600 hover:text-blue-800">
                                <Edit2 size={14} />
                              </button>
                            </>
                          )}
                          {!allApproved && <RedDot />}
                        </span>
                        <ChevronRight className={`h-5 w-5 text-gray-400 transition-transform ${selectedEmployee === employee.id ? 'transform rotate-90' : ''}`} />
                      </button>
                      {selectedEmployee === employee.id && (
                        <div className="pl-6 pr-3 pb-3 space-y-4 bg-gray-100 rounded-lg mt-2">
                          {/* KRAs Section */}
                          <div>
                            <div className="flex items-center justify-between bg-gray-200 p-2 rounded">
                              <h3 className="text-sm font-semibold text-gray-700 uppercase tracking-wider flex items-center">
                                KRAs
                                {!allKrasApproved && <RedDot />}
                              </h3>
                              <div className="flex items-center space-x-2">
                                <button
                                  onClick={() => handleSelectAll(employee.id, 'kra')}
                                  className="text-sm text-blue-600 hover:text-blue-800"
                                >
                                  Select All
                                </button>
                                <button
                                  onClick={() => handleAddNew(employee.id, 'kra')}
                                  className="text-sm text-green-600 hover:text-green-800"
                                >
                                  <Plus size={18} />
                                </button>
                              </div>
                            </div>
                            <ul className="space-y-4 ml-4 mt-2">
                              {employee.kras.map((kra, index) => (
                                <li key={index} className="text-sm">
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center flex-grow mr-2">
                                      <input
                                        type="checkbox"
                                        checked={selectedItems.has(`${employee.id}-kra-${index}`)}
                                        onChange={() => handleSelectItem(employee.id, 'kra', index)}
                                        className="mr-2"
                                      />
                                      {editingKRA && editingKRA.employeeId === employee.id && editingKRA.index === index ? (
                                        <input
                                          value={editingKRA.description}
                                          onChange={(e) => setEditingKRA({ ...editingKRA, description: e.target.value })}
                                          className="flex-grow mr-2 px-1 border rounded"
                                        />
                                      ) : (
                                        <span className={`text-gray-600 ${kra.status === 'approved' ? 'bg-green-100 px-1 rounded' : ''}`}>
                                          {kra.description}
                                        </span>
                                      )}
                                    </div>
                                    <div className="flex items-center space-x-2">
                                      <button
                                        onClick={() => handleToggleApproval(employee.id, 'kra', index)}
                                        className={`${kra.status === 'approved' ? 'text-green-600 hover:text-green-800' : 'text-gray-400 hover:text-gray-600'}`}
                                      >
                                        <Check size={18} />
                                      </button>
                                      {editingKRA && editingKRA.employeeId === employee.id && editingKRA.index === index ? (
                                        <button onClick={handleSaveKRA} className="text-blue-600 hover:text-blue-800">
                                          Save
                                        </button>
                                      ) : (
                                        <button onClick={() => handleEditKRA(employee.id, kra, index)} className="text-blue-600 hover:text-blue-800">
                                          <Edit2 size={18} />
                                        </button>
                                      )}
                                      <button onClick={() => handleDelete(employee.id, 'kra', index)} className="text-red-600 hover:text-red-800">
                                        <Trash2 size={18} />
                                      </button>
                                    </div>
                                  </div>
                                  {editingKRA && editingKRA.employeeId === employee.id && editingKRA.index === index ? (
                                    <ul className="ml-6 mt-2 list-disc text-sm text-gray-600">
                                      {editingKRA.responsibilities.map((responsibility, respIndex) => (
                                        <li key={respIndex} className="flex items-center mb-2 px-2">
                                          <input
                                            value={responsibility}
                                            onChange={(e) => {
                                              const newResponsibilities = [...editingKRA.responsibilities];
                                              newResponsibilities[respIndex] = e.target.value;
                                              setEditingKRA({ ...editingKRA, responsibilities: newResponsibilities });
                                            }}
                                            className="w-full px-2 py-1 border rounded mr-2 text-sm"
                                          />
                                          <button
                                            onClick={() => handleDeleteResponsibility(respIndex)}
                                            className="text-red-600 hover:text-red-800 ml-2"
                                          >
                                            <Trash2 size={16} />
                                          </button>
                                        </li>
                                      ))}
                                      <li className="px-2">
                                        <button
                                          onClick={() => setEditingKRA({ ...editingKRA, responsibilities: [...editingKRA.responsibilities, ''] })}
                                          className="text-green-600 hover:text-green-800 text-sm"
                                        >
                                          Add Responsibility
                                        </button>
                                      </li>
                                    </ul>
                                  ) : (
                                    <ul className="ml-6 mt-2 list-disc text-sm text-gray-600">
                                      {kra.responsibilities && kra.responsibilities.length > 0 ? (
                                        kra.responsibilities.map((responsibility, respIndex) => (
                                          <li key={respIndex} className={`mb-1 px-2 ${kra.status === 'approved' ? 'bg-green-100' : ''}`}>
                                            {responsibility}
                                          </li>
                                        ))
                                      ) : (
                                        <li className="px-2">No responsibilities found</li>
                                      )}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>

                          {/* KPIs Section */}
                          <div>
                            <div className="flex items-center justify-between bg-gray-200 p-2 rounded">
                              <h3 className="text-sm font-semibold text-gray-700 uppercase tracking-wider flex items-center">
                                KPIs
                                {!allKpisApproved && <RedDot />}
                              </h3>
                              <div className="flex items-center space-x-2">
                                <button
                                  onClick={() => handleSelectAll(employee.id, 'kpi')}
                                  className="text-sm text-blue-600 hover:text-blue-800"
                                >
                                  Select All
                                </button>
                                <button
                                  onClick={() => handleAddNew(employee.id, 'kpi')}
                                  className="text-sm text-green-600 hover:text-green-800"
                                >
                                  <Plus size={18} />
                                </button>
                              </div>
                            </div>
                            <ul className="space-y-2 ml-4 mt-2">
                              {employee.kpis.map((kpi, index) => (
                                <li key={index} className="flex items-center justify-between text-sm">
                                  <div className="flex items-center flex-grow mr-2">
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.has(`${employee.id}-kpi-${index}`)}
                                      onChange={() => handleSelectItem(employee.id, 'kpi', index)}
                                      className="mr-2"
                                    />
                                    {editingKPI && editingKPI.employeeId === employee.id && editingKPI.index === index ? (
                                      <input
                                        value={editingKPI.description}
                                        onChange={(e) => setEditingKPI({ ...editingKPI, description: e.target.value })}
                                        className="flex-grow mr-2 px-1 border rounded"
                                      />
                                    ) : (
                                      <span className={`text-gray-600 ${kpi.status === 'approved' ? 'bg-green-100 px-1 rounded' : ''}`}>
                                        {kpi.description}
                                      </span>
                                    )}
                                  </div>
                                  <div className="flex items-center space-x-2">
                                    <button
                                      onClick={() => handleToggleApproval(employee.id, 'kpi', index)}
                                      className={`${kpi.status === 'approved' ? 'text-green-600 hover:text-green-800' : 'text-gray-400 hover:text-gray-600'}`}
                                    >
                                      <Check size={18} />
                                    </button>
                                    {editingKPI && editingKPI.employeeId === employee.id && editingKPI.index === index ? (
                                      <button onClick={handleSaveKPI} className="text-blue-600 hover:text-blue-800">
                                        Save
                                      </button>
                                    ) : (
                                      <button onClick={() => handleEditKPI(employee.id, kpi, index)} className="text-blue-600 hover:text-blue-800">
                                        <Edit2 size={18} />
                                      </button>
                                    )}
                                    <button onClick={() => handleDelete(employee.id, 'kpi', index)} className="text-red-600 hover:text-red-800">
                                      <Trash2 size={18} />
                                    </button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Edit Item Dialog */}
      {editingItem !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Edit {editingItem?.type.toUpperCase()}</h2>
            <input
              type="text"
              value={editingItem?.description || ''}
              onChange={(e) => setEditingItem(prev => prev ? { ...prev, description: e.target.value } : null)}
              className="w-full px-3 py-2 border rounded"
            />
            <div className="mt-4 flex justify-end">
              <button onClick={() => setEditingItem(null)} className="mr-2 px-4 py-2 border rounded">Cancel</button>
              <button onClick={handleSaveEdit} className="px-4 py-2 bg-blue-500 text-white rounded">Save</button>
            </div>
          </div>
        </div>
      )}

      {/* Add New Item Dialog */}
      {newItemType !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Add New {newItemType?.type.toUpperCase()}</h2>
            <input
              type="text"
              value={newItemDescription}
              onChange={(e) => setNewItemDescription(e.target.value)}
              placeholder={`Enter new ${newItemType?.type.toUpperCase()} description`}
              className="w-full px-3 py-2 border rounded"
            />
            <div className="mt-4 flex justify-end">
              <button onClick={() => setNewItemType(null)} className="mr-2 px-4 py-2 border rounded">Cancel</button>
              <button onClick={handleSaveNewItem} className="px-4 py-2 bg-blue-500 text-white rounded">Add</button>
            </div>
          </div>
        </div>
      )}
    </KPIContext.Provider>
  )
}
