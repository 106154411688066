import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactFlow, {
  Node,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  ReactFlowInstance,
} from 'reactflow';
import 'reactflow/dist/style.css';
import api from '../api';
import { useAuth } from '../contexts/AuthContext';
import CustomNode from './CustomNode';
import CustomEdge from './CustomEdge';
import NodeSidebar from './NodeSidebar';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

type OrgChartNode = Node<{
  title: string;
  department: string;
  color: string;
  isIc: boolean;
  grade: string;
  location: string;
  currentEmployee: {
    id: string;
    name: string;
    email: string;
  } | null;
}>;

const EmployeeOrgChart: React.FC = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState<OrgChartNode | null>(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user } = useAuth();
  const initialViewport = useRef({ x: 0, y: 0, zoom: 0.9 });

  useEffect(() => {
    if (user?.employeeId) {
      fetchEmployeeOrgChart();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const updateChartSize = () => {
      const container = document.getElementById('org-chart-container');
      if (container) {
        // Calculate available height (viewport height minus any headers/margins)
        const availableHeight = window.innerHeight - 64; // Assuming 64px for header
        container.style.height = `${availableHeight}px`;
      }
    };

    // Initial size set
    updateChartSize();

    // Update on window resize
    window.addEventListener('resize', updateChartSize);

    // Cleanup
    return () => window.removeEventListener('resize', updateChartSize);
  }, []);

  const fetchEmployeeOrgChart = async () => {
    if (!user?.employeeId) {
      setError('No employee ID found');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await api.get(`/api/employee-org-chart/${user.employeeId}/`);
      const { nodes: apiNodes, edges: apiEdges, employeeNodeId } = response.data;

      console.log('API Response:', apiNodes);

      const transformedNodes = apiNodes.map((node: any) => ({
        id: node.id.toString(),
        type: 'custom',
        position: node.position,
        data: {
          title: node.data.title,
          department: node.data.department,
          color: node.data.color,
          isIc: node.data.isIc,
          grade: node.data.grade,
          location: node.data.location,
          currentEmployee: node.data.currentEmployee ? {
            id: node.data.currentEmployee.id,
            name: node.data.currentEmployee.name,
            email: node.data.currentEmployee.email
          } : null,
        },
      }));

      console.log('Transformed Nodes:', transformedNodes);

      const transformedEdges = apiEdges.map((edge: any) => ({
        id: edge.id,
        source: edge.source.toString(),
        target: edge.target.toString(),
        type: 'custom',
        data: { type: edge.data?.type || 'default' },
      }));

      setNodes(transformedNodes);
      setEdges(transformedEdges);

      if (employeeNodeId) {
        setTimeout(() => {
          const employeeNode = transformedNodes.find((node: OrgChartNode) => node.id === employeeNodeId.toString());
          if (employeeNode && reactFlowInstance) {
            const viewport = {
              x: -(employeeNode.position.x - window.innerWidth / 3),
              y: -(employeeNode.position.y - window.innerHeight / 3),
              zoom: 0.9,
            };
            reactFlowInstance.setViewport(viewport);
            initialViewport.current = viewport;
          }
        }, 100);
      }
    } catch (error) {
      console.error('Error fetching org chart data:', error);
      setError('Failed to load organization chart');
    } finally {
      setLoading(false);
    }
  };

  const onNodeDoubleClick = useCallback(
    async (event: React.MouseEvent, node: Node) => {
      event.preventDefault();
      try {
        const response = await api.get(`/api/employee-dashboard/${node.id}/`);
        setEmployeeDetails(response.data);
        setSelectedNode(node as OrgChartNode);
        setSidebarOpen(true);
      } catch (error) {
        console.error('Error fetching employee details:', error);
      }
    },
    []
  );

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading organization chart...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-600">{error}</div>;
  }

  return (
    <div 
      id="org-chart-container" 
      className="w-full flex" 
      style={{ 
        position: 'relative',
        height: 'calc(100vh - 64px)' // Fallback height
      }}
    >
      <div 
        style={{ 
          width: sidebarOpen ? '50%' : '100%',
          height: '100%', // Make sure this div takes full height
          transition: 'width 0.3s ease-in-out',
          background: 'linear-gradient(to right, #FFF5D6, #F2E2A8)'
        }}
      >
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDoubleClick={onNodeDoubleClick}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onInit={setReactFlowInstance}
            defaultEdgeOptions={{ type: 'custom' }}
            zoomOnScroll={false}
            panOnScroll={true}
            panOnScrollMode="free" as const
            zoomOnPinch={true}
            zoomOnDoubleClick={false}
            fitView
            style={{ 
              background: 'linear-gradient(to right, #FFF5D6, #F2E2A8)',
              height: '100%' // Ensure ReactFlow takes full height
            }}
          >
            <Controls />
            <Background color="#E5E5C9" />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
      {sidebarOpen && selectedNode && employeeDetails && (
        <NodeSidebar
          node={selectedNode}
          employeeDetails={employeeDetails}
          onClose={() => setSidebarOpen(false)}
          onUpdate={() => {}}
          userId={user?.userId || ''}
        />
      )}
    </div>
  );
};

export default EmployeeOrgChart;
