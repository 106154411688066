"use client"

import { useState, useEffect } from "react"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Textarea } from "../components/ui/textarea"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../components/ui/dialog"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar"
import { Progress } from "../components/ui/progress"
import { Label } from "../components/ui/label"
import { Checkbox } from "../components/ui/checkbox"
import { ChevronLeft, ChevronRight, X, Edit3, Trash2 } from "lucide-react"
import { useAuth } from '../contexts/AuthContext';
import api from '../api';
import { Slider } from "./ui/slider"
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogCancel, AlertDialogAction, AlertDialogTrigger } from "./ui/alert-dialog"

type GoalUpdate = {
  id: number;
  type: "self" | "internal" | "external";
  content: string;
  progress: number;
  raters: string[];
  review_status: 'pending' | 'awaiting_feedback' | 'completed';
  review_rating?: number;
  review_comment?: string;
  created_at: string;
};

type Goal = {
  id: number;
  title: string;
  description: string;
  goal_type: 'personal' | 'company';
  progress: number;
  owner: string | null;
  owner_details: {
    id: number;
    employee_id: string;
    name: string;
    email: string;
    date_of_joining: string;
    is_active: boolean;
    competencies: string[];
  } | null;
  internal_reviewer_details: Array<{
    id: number;
    employee_id: string;
    name: string;
    email: string;
    date_of_joining: string;
    is_active: boolean;
    competencies: string[];
  }>;
  external_reviewers: string[];
  timeline: string;
  timeline_duration: string;
  timeline_year: number;
  company: string;
  created_at: string;
  updated_at: string;
  updates: GoalUpdate[];
  parent_goal?: number | null;
  parent_goal_details?: {
    id: number;
    title: string;
    progress: number;
  } | null;
};

// type Update = {
//   id: string;
//   goalId: number;
//   type: "self" | "internal" | "external";
//   content: string;
//   progress: number;
//   date: string;
//   raters?: string[];
// };

type User = {
  userId?: string;
  employeeId?: string;
  company?: string;
  role?: string;
}

type Employee = {
  id: number;
  employee_id: string;
  name: string;
  email: string;
  date_of_joining: string;
  is_active: boolean;
};

export default function Goals() {
  const { user } = useAuth();
  const [goals, setGoals] = useState<Goal[]>([]);
  // const [updates, setUpdates] = useState<Update[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<User>({
    id: "1",
    name: "Default User",
    role: "employee"
  });
  const [personalGoals, setPersonalGoals] = useState<Goal[]>([]);
  const [isPersonalDialogOpen, setIsPersonalDialogOpen] = useState(false);
  const [isCompanyDialogOpen, setIsCompanyDialogOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setCurrentUser({
        id: user.userId || "1",
        name: user.employeeId || "Default User",
        role: user.role === "promoter" ? "founder" : "employee"
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchGoals = async () => {
      if (!user?.company) return;
      
      try {
        setLoading(true);
        
        if (user.role === 'promoter') {
          const [companyResponse, personalResponse] = await Promise.all([
            api.get(`/api/companies/${user.company}/goals/?include_updates=true`),
            api.get(`/api/companies/${user.company}/goals/`, {
              params: {
                type: 'personal',
                include_updates: true
              }
            })
          ]);
          
          setGoals(companyResponse.data);
          setPersonalGoals(personalResponse.data);
        } else {
          const [personalResponse, companyResponse] = await Promise.all([
            api.get(`/api/companies/${user.company}/goals/`, {
              params: {
                type: 'personal',
                employee_id: user.employeeId,
                include_updates: true
              }
            }),
            api.get(`/api/companies/${user.company}/goals/`, {
              params: {
                type: 'company',
                include_updates: true
              }
            })
          ]);
          
          setPersonalGoals(personalResponse.data);
          setGoals(companyResponse.data);
        }
        
        setError(null);
      } catch (err) {
        console.error('Error fetching goals:', err);
        setError('Failed to fetch goals');
      } finally {
        setLoading(false);
      }
    };

    fetchGoals();
  }, [user?.company, user?.employeeId, user?.role]);

  const addGoal = async (newGoal: Omit<Goal, 'id' | 'created_at' | 'updated_at'>) => {
    if (!user?.company) return;

    try {
      const payload = {
        ...newGoal,
        goal_type: newGoal.goal_type,
        owner: newGoal.goal_type === 'personal' ? 
          (user.role === 'promoter' ? newGoal.owner : user.userId) : 
          undefined
      };

      const response = await api.post(
        `/api/companies/${user.company}/goals/`,
        payload
      );

      if (newGoal.goal_type === 'personal') {
        setPersonalGoals(prevGoals => [...prevGoals, response.data]);
      } else {
        setGoals(prevGoals => [...prevGoals, response.data]);
      }
    } catch (error) {
      console.error('Error creating goal:', error);
      throw error;
    }
  };

  const addUpdate = async (goalId: number, updateData: Omit<GoalUpdate, 'id'>) => {
    if (!user?.company) return;

    try {
      const response = await api.post(
        `/api/companies/${user.company}/goals/${goalId}/updates/`,
        updateData
      );

      // Update the goals state with the new update
      const updateGoals = (goals: Goal[]) => 
        goals.map(goal => 
          goal.id === goalId 
            ? { ...goal, updates: [...goal.updates, response.data] }
            : goal
        );

      setGoals(prev => updateGoals(prev));
      setPersonalGoals(prev => updateGoals(prev));
    } catch (error) {
      console.error('Error adding update:', error);
      throw error;
    }
  };

  const defaultTab = user?.role === "promoter" ? "all-goals" : "personal-goals"

  const updateGoal = async (goalId: number, updatedData: Partial<Goal>) => {
    if (!user?.company) return;

    try {
      const response = await api.put(
        `/api/companies/${user.company}/goals/${goalId}/`,
        updatedData
      );

      // Update the appropriate goals list
      if (response.data.goal_type === 'personal') {
        setPersonalGoals(prev => prev.map(goal => 
          goal.id === goalId ? response.data : goal
        ));
      } else {
        setGoals(prev => prev.map(goal => 
          goal.id === goalId ? response.data : goal
        ));
      }
    } catch (error) {
      console.error('Error updating goal:', error);
      throw error;
    }
  };

  const deleteGoal = async (goalId: number, goalType: 'personal' | 'company') => {
    if (!user?.company) return;

    try {
      await api.delete(`/api/companies/${user.company}/goals/${goalId}/`);
      
      // Remove from the appropriate goals list
      if (goalType === 'personal') {
        setPersonalGoals(prev => prev.filter(goal => goal.id !== goalId));
      } else {
        setGoals(prev => prev.filter(goal => goal.id !== goalId));
      }
    } catch (error) {
      console.error('Error deleting goal:', error);
      throw error;
    }
  };

  if (loading) {
    return <div className="container mx-auto p-4">Loading goals...</div>;
  }

  if (error) {
    return <div className="container mx-auto p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4 min-h-[calc(100vh-8rem)]">
      <h1 className="text-2xl font-bold mb-4">Goal Setting Tool</h1>
      <Tabs defaultValue={defaultTab}>
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="personal-goals">
            {user?.role === 'promoter' ? "All Personal Goals" : "Personal Goals"}
          </TabsTrigger>
          <TabsTrigger value="all-goals">Company Goals</TabsTrigger>
        </TabsList>

        <TabsContent value="personal-goals">
          <Card>
            <CardHeader>
              <CardTitle>
                {user?.role === 'promoter' ? "All Personal Goals" : "Personal Goals"}
              </CardTitle>
              <CardDescription>
                {user?.role === 'promoter' 
                  ? "View and create personal development goals for employees"
                  : "Set and track your personal development goals"
                }
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Dialog open={isPersonalDialogOpen} onOpenChange={setIsPersonalDialogOpen}>
                <DialogTrigger asChild>
                  <Button className="mb-4" onClick={() => setIsPersonalDialogOpen(true)}>Add Personal Goal</Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Create a new personal goal</DialogTitle>
                  </DialogHeader>
                  <AddGoalForm 
                    addGoal={addGoal} 
                    currentUser={currentUser} 
                    goalType="personal"
                    isPromoter={user?.role === 'promoter'}
                    onClose={() => setIsPersonalDialogOpen(false)}
                  />
                </DialogContent>
              </Dialog>
              <GoalList 
                goals={personalGoals} 
                currentUser={currentUser} 
                addUpdate={addUpdate}
                updateGoal={updateGoal}
                deleteGoal={deleteGoal}
              />
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="all-goals">
          <Card>
            <CardHeader>
              <CardTitle>Company Goals</CardTitle>
              <CardDescription>All company goals are listed here.</CardDescription>
            </CardHeader>
            <CardContent>
              <Dialog open={isCompanyDialogOpen} onOpenChange={setIsCompanyDialogOpen}>
                <DialogTrigger asChild>
                  <Button className="mb-4" onClick={() => setIsCompanyDialogOpen(true)}>Add Company Goal</Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Create a new company goal</DialogTitle>
                  </DialogHeader>
                  <AddGoalForm 
                    addGoal={addGoal} 
                    currentUser={currentUser} 
                    goalType="company"
                    isPromoter={user?.role === 'promoter'}
                    onClose={() => setIsCompanyDialogOpen(false)}
                  />
                </DialogContent>
              </Dialog>
              <GoalList 
                goals={goals.filter(goal => goal.goal_type === "company")} 
                currentUser={currentUser} 
                addUpdate={addUpdate}
                updateGoal={updateGoal}
                deleteGoal={deleteGoal}
              />
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  )
}

function AddGoalForm({ 
  addGoal, 
  currentUser, 
  goalType,
  isPromoter = false,
  onClose,
  initialData
}: { 
  addGoal: (goal: any) => void, 
  currentUser: User,
  goalType: 'personal' | 'company',
  isPromoter?: boolean,
  onClose: () => void,
  initialData?: Goal
}) {
  const { user } = useAuth();
  const [description, setDescription] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState<string>(
    initialData?.owner || ""
  );
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [timelineDuration, setTimelineDuration] = useState<string>("FY");
  const [timelineYear, setTimelineYear] = useState<number>(new Date().getFullYear());
  const [internalReviewers, setInternalReviewers] = useState<string[]>([]);
  const [externalReviewers, setExternalReviewers] = useState<string[]>([]);
  const [selectedInternalReviewers, setSelectedInternalReviewers] = useState<string[]>([]);
  const [selectedParentGoal, setSelectedParentGoal] = useState<string>(
    initialData?.parent_goal?.toString() || 'none'
  );
  const [companyGoals, setCompanyGoals] = useState<Goal[]>([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (!user?.company) return;
      
      try {
        const response = await api.get(`/api/employees/${user.company}`);
        setEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, [user?.company]);

  useEffect(() => {
    const fetchCompanyGoals = async () => {
      if (!user?.company || goalType !== 'personal') return;
      
      try {
        const response = await api.get(`/api/companies/${user.company}/goals/`, {
          params: { type: 'company' }
        });
        setCompanyGoals(response.data);
      } catch (error) {
        console.error('Error fetching company goals:', error);
      }
    };

    fetchCompanyGoals();
  }, [user?.company, goalType]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      await addGoal({
        title: description,
        description: description,
        goal_type: goalType,
        timeline: `${timelineDuration}${timelineYear}`,
        progress: 0,
        internal_reviewers: internalReviewers,
        external_reviewers: externalReviewers,
        company: user?.company || '',
        parent_goal: selectedParentGoal === 'none' ? null : parseInt(selectedParentGoal),
        ...(goalType === 'personal' && {
          owner: isPromoter ? selectedEmployee : user?.userId
        })
      });
      
      // Clear form and close dialog
      setDescription("");
      setSelectedEmployee("");
      setTimelineDuration("FY");
      setTimelineYear(new Date().getFullYear());
      setInternalReviewers([]);
      setExternalReviewers([]);
      setSelectedParentGoal('none');
      onClose();
    } catch (error) {
      console.error('Error submitting goal:', error);
    }
  };

  const handleYearChange = (direction: 'prev' | 'next') => {
    setTimelineYear(prev => direction === 'prev' ? prev - 1 : prev + 1);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="goal-description">Goal description <span className="text-red-500">*</span></Label>
        <Textarea
          id="goal-description"
          placeholder="Enter goal description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>

      {isPromoter && goalType === 'personal' && (
        <div>
          <Label htmlFor="employee-select">Select Employee <span className="text-red-500">*</span></Label>
          <Select 
            value={selectedEmployee} 
            onValueChange={setSelectedEmployee}
          >
            <SelectTrigger id="employee-select" className="w-full bg-background">
              <SelectValue placeholder="Select an employee" />
            </SelectTrigger>
            <SelectContent className="bg-background max-h-[200px] overflow-y-auto">
              {employees.map((employee) => (
                <SelectItem key={employee.id} value={employee.employee_id}>
                  {employee.name} ({employee.employee_id})
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      <div>
        <Label htmlFor="internal-reviewers">Internal Reviewers</Label>
        <Select 
          onValueChange={(value) => {
            const currentReviewers = selectedInternalReviewers;
            if (!currentReviewers.includes(value)) {
              setSelectedInternalReviewers([...currentReviewers, value]);
              setInternalReviewers([...currentReviewers, value]);
            }
          }}
        >
          <SelectTrigger id="internal-reviewers" className="w-full bg-background">
            <SelectValue placeholder="Add reviewers" />
          </SelectTrigger>
          <SelectContent className="bg-background max-h-[200px] overflow-y-auto">
            {employees.map((employee) => (
              <SelectItem 
                key={employee.id} 
                value={employee.name}
                disabled={selectedInternalReviewers.includes(employee.name)}
                className="cursor-pointer"
              >
                {employee.name} ({employee.employee_id})
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        
        <div className="mt-2 space-y-2">
          {selectedInternalReviewers.map((reviewer) => (
            <div key={reviewer} className="flex items-center justify-between bg-muted p-2 rounded-md">
              <span className="text-sm">{reviewer}</span>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedInternalReviewers(prev => prev.filter(r => r !== reviewer));
                  setInternalReviewers(prev => prev.filter(r => r !== reviewer));
                }}
                className="text-red-500 hover:text-red-700"
              >
                <X size={16} />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Label htmlFor="external-reviewers">External Reviewers (comma-separated emails)</Label>
        <Input
          id="external-reviewers"
          placeholder="example1@email.com, example2@email.com"
          value={externalReviewers.join(', ')}
          onChange={(e) => setExternalReviewers(e.target.value.split(',').map(email => email.trim()))}
        />
      </div>
      <div className="space-y-2">
        <Label>Timeline</Label>
        <div className="flex justify-between items-center">
          <Button 
            type="button" 
            variant="outline" 
            size="sm" 
            onClick={() => handleYearChange('prev')}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <div className="font-semibold">{timelineYear}</div>
          <Button 
            type="button" 
            variant="outline" 
            size="sm" 
            onClick={() => handleYearChange('next')}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
        
        <div className="grid grid-cols-1 gap-2 mb-2">
          <Button
            type="button"
            variant={timelineDuration === "FY" ? "default" : "outline"}
            onClick={() => setTimelineDuration("FY")}
          >
            FY
          </Button>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <Button
            type="button"
            variant={timelineDuration === "H1" ? "default" : "outline"}
            onClick={() => setTimelineDuration("H1")}
          >
            H1
          </Button>
          <Button
            type="button"
            variant={timelineDuration === "H2" ? "default" : "outline"}
            onClick={() => setTimelineDuration("H2")}
          >
            H2
          </Button>
        </div>

        <div className="grid grid-cols-4 gap-2">
          {["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
            <Button
              key={quarter}
              type="button"
              variant={timelineDuration === quarter ? "default" : "outline"}
              onClick={() => setTimelineDuration(quarter)}
            >
              {quarter}
            </Button>
          ))}
        </div>
      </div>
      {goalType === 'personal' && companyGoals.length > 0 && (
        <div>
          <Label htmlFor="parent-goal">Link to Company Goal (Optional)</Label>
          <Select 
            value={selectedParentGoal} 
            onValueChange={setSelectedParentGoal}
          >
            <SelectTrigger id="parent-goal" className="w-full bg-background">
              <SelectValue placeholder="Select a company goal" />
            </SelectTrigger>
            <SelectContent className="bg-background max-h-[200px] overflow-y-auto">
              <SelectItem value="none">No Parent Goal</SelectItem>
              {companyGoals.map((goal) => (
                <SelectItem key={goal.id} value={goal.id.toString()}>
                  {goal.title}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
      <div className="flex justify-end space-x-2 pt-4">
        <DialogTrigger asChild>
          <Button variant="outline" type="button">Cancel</Button>
        </DialogTrigger>
        <Button type="submit">Save goal</Button>
      </div>
    </form>
  );
}

function GoalList({ 
  goals, 
  currentUser, 
  addUpdate,
  updateGoal,
  deleteGoal 
}: { 
  goals: Goal[], 
  currentUser: User, 
  addUpdate: (goalId: number, update: Omit<GoalUpdate, 'id'>) => Promise<void>,
  updateGoal: (goalId: number, updatedData: Partial<Goal>) => Promise<void>,
  deleteGoal: (goalId: number, goalType: 'personal' | 'company') => Promise<void>
}) {
  const [editingGoalId, setEditingGoalId] = useState<number | null>(null);
  const [updatingGoalId, setUpdatingGoalId] = useState<number | null>(null);

  return (
    <div className="space-y-4">
      {goals.map((goal) => (
        <Card key={goal.id}>
          <CardHeader className="flex flex-row items-start justify-between space-y-0">
            <div>
              <CardTitle>{goal.description}</CardTitle>
              <CardDescription>{goal.timeline}</CardDescription>
            </div>
            <div className="flex gap-2">
              <Dialog open={editingGoalId === goal.id} onOpenChange={(open) => setEditingGoalId(open ? goal.id : null)}>
                <DialogTrigger asChild>
                  <Button variant="ghost" size="sm">
                    <Edit3 className="h-4 w-4" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Edit Goal</DialogTitle>
                  </DialogHeader>
                  <AddGoalForm 
                    addGoal={(updatedData) => {
                      updateGoal(goal.id, updatedData);
                      setEditingGoalId(null);
                    }}
                    currentUser={currentUser}
                    goalType={goal.goal_type}
                    isPromoter={currentUser.role === 'founder'}
                    onClose={() => setEditingGoalId(null)}
                    initialData={goal}
                  />
                </DialogContent>
              </Dialog>
              
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button 
                    variant="ghost" 
                    size="sm"
                    className="text-red-600 hover:text-red-700 hover:bg-red-50"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Delete Goal</AlertDialogTitle>
                    <AlertDialogDescription>
                      Are you sure you want to delete this goal? This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => deleteGoal(goal.id, goal.goal_type)}
                      className="bg-red-600 hover:bg-red-700"
                    >
                      Delete
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </CardHeader>
          <CardContent>
            {goal.owner_details && (
              <div className="flex items-center space-x-4 mb-2">
                <Avatar>
                  <AvatarImage src="/placeholder-avatar.jpg" />
                  <AvatarFallback>{goal.owner_details.name[0]}</AvatarFallback>
                </Avatar>
                <div>
                  <p className="text-sm font-medium">{goal.owner_details.name}</p>
                  <p className="text-sm text-muted-foreground">{goal.goal_type}</p>
                </div>
              </div>
            )}
            <div className="space-y-2">
              <Progress 
                value={goal.progress} 
                className="mb-2 h-2 bg-gray-100" 
              />
              <p className="text-sm text-muted-foreground text-right">{goal.progress}% complete</p>
            </div>
            <p className="text-sm text-muted-foreground">
              Company: {goal.company}
            </p>
            {goal.internal_reviewer_details.length > 0 && (
              <p className="text-sm text-muted-foreground">
                Internal Reviewers: {goal.internal_reviewer_details.map(r => r.name).join(', ')}
              </p>
            )}
            
            {goal.updates && goal.updates.length > 0 && (
              <div className="mt-4 space-y-3">
                <h4 className="text-sm font-semibold">Updates</h4>
                {goal.updates.map((update) => (
                  <div 
                    key={update.id} 
                    className="bg-background border border-black rounded-lg p-3 space-y-2"
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <span className="text-sm font-medium capitalize">
                          {update.type} Update
                        </span>
                        <p className="text-sm mt-1">{update.content}</p>
                      </div>
                      {update.progress > 0 && (
                        <span className="text-xs bg-black text-white px-2 py-1 rounded-full">
                          {update.progress}%
                        </span>
                      )}
                    </div>
                    
                    {update.raters && update.raters.length > 0 && (
                      <div className="text-xs text-muted-foreground">
                        Reviewers: {update.raters.join(', ')}
                      </div>
                    )}
                    
                    {update.review_status === 'completed' && (
                      <div className="mt-2 pt-2 border-t border-black">
                        <div className="flex justify-between items-center">
                          <span className="text-sm font-medium">Review Rating: {update.review_rating}/10</span>
                          <span className="text-xs bg-green-100 text-black border border-black px-2 py-1 rounded-full">
                            Reviewed
                          </span>
                        </div>
                        {update.review_comment && (
                          <p className="text-sm mt-1 text-muted-foreground">
                            "{update.review_comment}"
                          </p>
                        )}
                      </div>
                    )}
                    
                    <div className="text-xs text-muted-foreground mt-2">
                      {new Date(update.created_at).toLocaleDateString()}
                    </div>
                  </div>
                ))}
              </div>
            )}
            
            {(goal.goal_type === 'company' || 
              (goal.goal_type === 'personal' && goal.owner_details?.id.toString() === currentUser.id)) && (
              <Dialog 
                open={updatingGoalId === goal.id} 
                onOpenChange={(open) => setUpdatingGoalId(open ? goal.id : null)}
              >
                <DialogTrigger asChild>
                  <Button className="mt-4 w-full border border-black">
                    Post Update
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Post Update for {goal.description}</DialogTitle>
                  </DialogHeader>
                  <UpdateForm 
                    goalId={goal.id}
                    addUpdate={addUpdate}
                    members={goal.internal_reviewer_details.map(r => r.name)} 
                    goal={goal}
                  />
                </DialogContent>
              </Dialog>
            )}
            {goal.parent_goal_details && (
              <div className="mt-2 text-sm">
                <span className="text-muted-foreground">Linked to company goal: </span>
                <span className="font-medium">{goal.parent_goal_details.title}</span>
                <div className="mt-1">
                  <Progress 
                    value={goal.parent_goal_details.progress} 
                    className="h-2 bg-gray-100"
                  />
                  <span className="text-xs text-muted-foreground">
                    Company goal progress: {goal.parent_goal_details.progress}%
                  </span>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

function UpdateForm({ 
  goalId, 
  addUpdate, 
  members,
  goal
}: { 
  goalId: number,
  addUpdate: (goalId: number, update: Omit<GoalUpdate, 'id'>) => Promise<void>,
  members: string[],
  goal: Goal
}) {
  const [type, setType] = useState<"self" | "internal" | "external">("self")
  const [content, setContent] = useState("")
  const [progress, setProgress] = useState(0)
  const [selectedRaters, setSelectedRaters] = useState<string[]>([])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const updateData: Omit<GoalUpdate, 'id'> = {
        type,
        content,
        progress: type === 'self' ? progress : 0,
        raters: type === "internal" ? selectedRaters : [],
        review_status: 'pending',
        created_at: new Date().toISOString()
      };

      await addUpdate(goalId, updateData);
      
      // Clear form
      setContent("");
      setProgress(0);
      setSelectedRaters([]);
      setType("self");
    } catch (error) {
      console.error('Error submitting update:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="update-type">Update Type</Label>
        <Select value={type} onValueChange={(value) => setType(value as "self" | "internal" | "external")}>
          <SelectTrigger id="update-type" className="bg-background">
            <SelectValue placeholder="Select update type" />
          </SelectTrigger>
          <SelectContent className="bg-background">
            <SelectItem value="self">Self</SelectItem>
            <SelectItem value="internal">Internal</SelectItem>
            <SelectItem value="external">External</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label htmlFor="update-content">Update Content</Label>
        <Textarea
          id="update-content"
          placeholder="Enter update details"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
      </div>
      
      {type === 'self' ? (
        <div className="space-y-2">
          <Label htmlFor="progress">Progress ({progress}%)</Label>
          <Slider
            id="progress"
            min={0}
            max={100}
            step={1}
            value={[progress]}
            onValueChange={(value) => setProgress(value[0])}
            className="w-full"
          />
        </div>
      ) : type === 'internal' ? (
        <div>
          <Label>Select Internal Reviewers</Label>
          {goal.internal_reviewer_details.map(reviewer => (
            <div key={reviewer.id} className="flex items-center space-x-2">
              <Checkbox
                id={`reviewer-${reviewer.id}`}
                checked={selectedRaters.includes(reviewer.email)}
                onCheckedChange={(checked) => {
                  setSelectedRaters(
                    checked
                      ? [...selectedRaters, reviewer.email]
                      : selectedRaters.filter((r) => r !== reviewer.email)
                  )
                }}
              />
              <Label htmlFor={`reviewer-${reviewer.id}`}>{reviewer.name}</Label>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <Label>Select External Reviewers</Label>
          {goal.external_reviewers.map(email => (
            <div key={email} className="flex items-center space-x-2">
              <Checkbox
                id={`reviewer-${email}`}
                checked={selectedRaters.includes(email)}
                onCheckedChange={(checked) => {
                  setSelectedRaters(
                    checked
                      ? [...selectedRaters, email]
                      : selectedRaters.filter((r) => r !== email)
                  )
                }}
              />
              <Label htmlFor={`reviewer-${email}`}>{email}</Label>
            </div>
          ))}
        </div>
      )}
      
      <Button type="submit">Post Update</Button>
    </form>
  )
}